<template>
	<section class="content redesign">
		<form class="row" role="form" @submit.prevent="submitForm">
			<div class="col-12 position-static" style="top: 5rem;z-index: 1000">
				<div class="card border rounded mb-0">
					<div class="card-body">
						<div class="form-group mb-0">
							<div class="row">
								<div class="col-sm">
									<div class="form-control bos-alert-warning lh-1 d-flex align-items-center h-100 w-fit">
										<div class="row">
											<div class="col-auto d-flex align-items-center">
												<i class="fi fi-rr-info d-flex align-items-center"></i>
											</div>
											<div class="col d-flex align-items-center">
												<div class="text-md">
													Pelajari cara mudah untuk tambah banyak pesanan sekaligus. <a href="//help.boscod.com/video-cara-membuat-pesanan-massal-upload/" target="_blank" class="text-primary">Lihat Panduan Pengguna</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-auto mt-xs-2">
									<button type="submit" @click="submitType='draft'" id="draft" ref="createOrder" class="d-none"></button>
									<button v-if="this.group_id != null || this.$route.params.id == null" type="button" @click="draftForm" class="btn btn-block btn-outline-primary font-weight-bold h-100 py-3 px-5" style="border-width: 2px;" :disabled="submitting">
										Simpan ke Draft
										<span v-if="submitting" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 mt-3">
				<div class="card border rounded mb-3">
					<div class="card-body">
						<div class="h6 font-weight-bold pb-2 border-bottom mb-3">Informasi Pengiriman</div>
						<div v-if="retur_exist == true" class="form-group">
							<div class="form-control bos-alert-warning lh-1 d-flex align-items-center h-100 w-fit">
								<div class="row">
									<div class="col-auto d-flex align-items-center">
										<i class="fi fi-rr-info d-flex align-items-center"></i>
									</div>
									<div class="col d-flex align-items-center">
										<div class="text-md">
											Alamat penerima sudah pernah melakukan retur. Pastikan data pengiriman sudah sesuai dan benar agar meminimalisir resiko retur
										</div>
									</div>
									<div class="col-auto d-flex align-items-start" style="cursor:pointer;" @click="closeNotifRetur">
										<i class="fi fi-rr-cross-small d-flex align-items-center"></i>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="row">
								<div class="col">
									<label>Alamat Pengirim</label>
								</div>
							</div>
							<div class="form-control d-flex justify-content-between align-items-center h-100 py-3 cursor-pointer" v-on:click="selectWarehouse" v-bind:class="{ 'is-invalid': sender.error }">
								<div v-if="sender.id" class="d-flex flex-column" style="gap: 6px">
									<div class="font-weight-bold">{{ sender.senderName }}</div>
									<div>{{ sender.senderTel }} ({{ sender.pic_name }})</div>
									<div>{{ sender.addr_detail }}</div>
									<div>{{ sender.kecamatan }}, {{ sender.kota }}, {{ sender.kodepos }}</div>
								</div>
								<div v-else class="text-muted">
									Masukkan alamat pengirim
								</div>
								<i class="fi fi-rr-edit text-primary" style="font-size: larger;"></i>
							</div>
						</div>
						<div class="form-group">
							<div class="row">
								<div class="col">
									<div class="form-control h-100">
										<div class="icheck-material-orange w-100">
											<input type="checkbox" id="dropship" v-model="dropship.checker" />
											<label for="dropship" class="form-check-label w-100">Dropship</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" v-if="dropship.checker">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Nama Dropshipper <span class="text-primary">(wajib diisi)</span></label>
								</div>
							</div>
							<input type="text" class="form-control" v-model="dropship.senderName" v-bind:required="dropship.checker" placeholder="Cth: Rajib Wijaya" :required="dropship.checker" />
						</div>
						<div class="form-group" v-if="dropship.checker">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Nomor Telepon Dropshipper <span class="text-primary">(wajib diisi)</span></label>
								</div>
							</div>
							<input type="text" class="form-control" v-model="dropship.senderTel" pattern="[0-9]{7,}" v-bind:required="dropship.checker" placeholder="Cth: 085XXXXXXXXX" :required="dropship.checker" />
						</div>
						<div class="form-group mb-0">
							<div class="row">
								<div class="col">
									<label>Alamat Penerima</label>
								</div>
							</div>
							<div class="form-control d-flex justify-content-between align-items-center h-100 py-3 cursor-pointer" v-on:click="showReceiver" v-bind:class="{ 'is-invalid': receiver.error }">
								<div v-if="receiver.confirm" class="d-flex flex-column" style="gap: 6px">
									<div class="font-weight-bold">{{ receiver.destName }}</div>
									<div v-if="validasi_wa == true" style="color: green;"><u>{{ receiver.destTel }}</u> <i class="fi fi-brands-whatsapp"></i></div>
									<div v-else>{{ receiver.destTel }} <i class="fi fi-brands-whatsapp"></i></div>
									<div>{{ receiver.addr_detail }}</div>
									<div>{{ receiver.destCity }}</div>
								</div>
								<div v-else class="text-muted">
									Masukkan alamat penerima
								</div>
								<i class="fi fi-rr-edit text-primary" style="font-size: larger;"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="card border rounded mb-0">
					<div class="card-body">
						<div class="h6 font-weight-bold pb-2 border-bottom mb-3">Detail Paket</div>
						<div class="form-group mb-0 py-2">
							<div class="row" v-if="false">
								<div class="col d-flex justify-content-between">
									<label>Detail Paket</label>
									<label class="control-label text-primary cursor-pointer" v-if="false">Pilih dari Katalog Produk <i class="fi fi-rr-angle-right"></i></label>
								</div>
							</div>
							<div class="form-control d-flex justify-content-between align-items-center h-100 py-3 cursor-pointer" v-on:click="showItem" v-bind:class="{ 'is-invalid': item.error }">
								<div v-if="item.confirm && item.item_name && item.weight" class="d-flex flex-column" style="gap: 6px">
									<div style="font-weight: bolder;" v-if="item.item_name">{{ item.item_name }}</div>
									<div style="font-weight: bold;">
										<span v-if="item.item_value">Rp {{ formatPrice(form.courier_id == 7 ? (item.codValue != 0 ? (item.codValue == null ? item.item_value : item.codValue) : item.item_value) : item.item_value) }}</span> 
										<sup v-if="item.item_value && (item.weight || item.category_name)">
											<sub style="opacity: 25%;">&#9679;</sub>
										</sup> 
										<span v-if="item.weight">{{ item.weight }} Kg</span> 
										<sup v-if="item.weight && item.category_name"><sub style="opacity: 25%;">&#9679;</sub></sup> <span v-if="item.category_name">{{ item.category_name }}</span>
									</div>
									<div><span v-if="item.length">P ({{ item.length }}cm)</span> <span v-if="item.length && (item.width || item.height)">&times;</span> <span v-if="item.width">L ({{ item.width }}cm)</span> <span v-if="item.width && item.height">&times;</span> <span v-if="item.height">T ({{ item.height }}cm)</span></div>
								</div>
								<div v-else class="text-muted">
									Masukkan detail paket kamu
								</div>
								<i class="fi fi-rr-edit text-primary" style="font-size: larger;"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 mt-3">
				<div class="card border rounded mb-3">
					<div class="card-body">
						<div class="h6 font-weight-bold pb-2 border-bottom mb-3">Detail Pengiriman</div>
						<div class="form-group">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Apa tipe pengirimanmu?</label>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="form-control h-100" v-bind:class="{ 'border-primary' : item.codStatus }" v-bind:style="{ backgroundColor: item.codStatus ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100" @click="!!codDisabled && verifKtp()">
										<!-- <div class="icheck-material-orange w-100"> -->
											<input type="radio" key="cod" id="cod" name="cod-status" v-model="item.codStatus" v-bind:value="true" @change="onToggleChange($event)" :disabled="codDisabled" required />
											<!-- <input type="radio" id="cod" name="cod-status" v-model="item.codStatus" v-bind:value="true" @change="onToggleChange($event)" required /> -->
											<label for="cod" class="form-check-label w-100">COD</label>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="form-control h-100" v-bind:class="{ 'border-primary' : !item.codStatus }" v-bind:style="{ backgroundColor: !item.codStatus ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100">
											<input type="radio" key="reg" id="reg" name="cod-status" v-model="item.codStatus" v-bind:value="false" @change="onToggleChange($event)" required />
											<label for="reg" class="form-check-label w-100">Non - COD</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group mb-2">
							<div class="row">
								<div class="col d-flex justify-content-between">
									<label>Kurir Pengiriman</label>
									<label class="control-label text-primary cursor-pointer" v-on:click="showCustomCourier">Pengaturan Kurir <i class="fi fi-rr-angle-right"></i></label>
								</div>
							</div>
							<div v-if="showServiceList" class="d-flex flex-column" style="gap: 0.5rem;">
								<div v-if="showServiceList" class="row justify-content-between align-items-center" style="margin-top: 5px; margin-bottom: 10px;">
									<div class="col">
										<div class="form-control py-2 px-2 h-100" v-bind:class="(service_category == 0 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
											<input type="radio" id="regular" name="service_category" v-model="service_category" value="0" hidden  @change="onToggleChangeService($event)"/>
											<label for="regular" class="form-check-label cursor-pointer w-100 d-flex flex-row" style=" justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 0 }">Reguler</label>
										</div>
									</div>
									<div class="col">
										<div class="form-control py-2 px-2 h-100" v-bind:class="(service_category == 2 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
											<input type="radio" id="sameday" name="service_category" v-model="service_category" value="2" hidden  @change="onToggleChangeService($event)"/>
											<label for="sameday" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 2 }">Sameday</label>
										</div>
									</div>
									<div class="col">
										<div class="form-control py-2 px-2 h-100" v-bind:class="(service_category == 3 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
											<input type="radio" id="nextday" name="service_category" v-model="service_category" value="3" hidden  @change="onToggleChangeService($event)"/>
											<label for="nextday" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 3 }">Nextday</label>
										</div>
									</div>
									<div class="col">
										<div class="form-control py-2 px-2 h-100" v-bind:class="(service_category == 1 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
											<input type="radio" id="cargo" name="service_category" v-model="service_category" value="1" hidden  @change="onToggleChangeService($event)"/>
											<label for="cargo" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 1 }">Cargo</label>
										</div>
									</div>
								</div>
								<div class="form-control h-100 py-3 cursor-pointer" v-on:click="selectService(sid, list.drop_off)" v-for="(list, sid) in listCourierSelect">
									<div class="row align-items-center">
										<div class="col-auto d-flex justify-content-center align-items-center px-0 px-sm-2" style="max-width: 30%;max-height: 33.275px">
											<img :src="getKurirLogo(list.kurir)" style="max-width: 100%;max-height: 60px;" />
										</div>
										<div class="col">
											<div class="font-weight-bold">{{ list.kurir + ' ' + list.serviceName }}</div>
											<div class="text-xs">{{ list.etd_from == 0 || list.etd_thru == 0 ? '&#8212;' : list.etd_from + '-' + list.etd_thru + ' hari' }}</div>
										</div>
										<div class="col-auto text-right">
											<del class="text-xs text-danger">{{ list.disc != 0 ? 'Rp ' + formatPrice(list.maxPrice) : '&nbsp;' }}</del>
											<div class="font-weight-bold">Rp {{ list.ppn_ongkir_inc == 0 ? formatPrice((list.price - list.disc) * (100 + list.ppn_ongkir) / 100) : formatPrice(list.price - list.disc) }}</div>
										</div>
									</div>
									<div  class="row align-items-center">
										<div v-if="list.rating > 0" class="row col-auto d-flex justify-content-center align-items-center px-0 px-sm-2" style="align-items: center; margin-left: 9px;">
											<star-rating :inline="true" :star-size="15" :read-only="true" :show-rating="false" :rating="list.rating" style="margin-bottom: 6px;" v-bind:increment="0.01"></star-rating>
											<h5 class="description-header" style="margin-top: 5px; margin-left: 5px;">{{ list.rating }}</h5>
										</div>
										<div v-else class="col-auto">
										</div>
										<div class="col">
										</div>
										<div class="col-auto text-right" style="margin-top: 5px;">
                                            <span v-if="list.cod == '1'" class="badge badge-warning" style="margin-right: 8px;">COD</span>
                                            <span class="badge badge-warning">Non COD</span>
										</div>
									</div>
								</div>
							</div>
							<div v-else-if="service.id" class="form-control h-100 py-3 border-primary cursor-pointer" style="background-color: #ffe1c9;border-width: 2px;" v-on:click="showServiceOption">
								<div class="row">
									<div class="col-auto d-flex justify-content-center align-items-center px-0 px-sm-2" style="max-width: 30%;max-height: 33.275px">
										<img :src="getKurirLogo(service.kurir)" style="max-width: 100%;max-height: 60px;" />
									</div>
									<div class="col">
										<div class="font-weight-bold">{{ service.kurir + ' ' + service.serviceName }}</div>
										<div class="text-xs">{{ service.etd_from == 0 || service.etd_thru == 0 ? '&#8212;' : service.etd_from + '-' + service.etd_thru + ' hari' }}</div>
									</div>
									<div class="col-auto text-right">
										<del class="text-xs text-danger">{{ service.disc != 0 ? 'Rp ' + formatPrice(service.maxPrice) : '&nbsp;' }}</del>
										<div class="font-weight-bold">Rp {{ service.ppn_ongkir_inc == 0 ? formatPrice((service.price - service.disc) * (100 + service.ppn_ongkir) / 100) : formatPrice(service.price - service.disc) }}</div>
									</div>
								</div>
							</div>
							<div v-else class="form-control d-flex justify-content-between align-items-center h-100 py-3 cursor-pointer">
								<div class="text-muted">
									<div class="font-weight-bold">Pilih kurir pengirimanmu</div>
									<div class="mt-2">Pilih kurir favoritmu dan nikmati diskon dari bosCOD!</div>
								</div>
								<i class="fi fi-rr-angle-right" style="opacity: 25%;"></i>
							</div>
							<div class="row mt-2" v-if="sender.addr_id && receiver.confirm && item.confirm && ongkirInProgres.length < listCourier.length">
								<div class="col">
									<div class="progress rounded-pill">
										<div class="progress-bar progress-bar-striped progress-bar-animated" :class="['bg-primary']" role="progressbar" v-bind:aria-valuenow="(ongkirInProgres.length / listCourier.length) * 100" aria-valuemin="0" aria-valuemax="100" :style="{ 'width': (ongkirInProgres.length / listCourier.length) * 100 + '%' }">{{ (ongkirInProgres.length / listCourier.length) * 100 }}%</div>
									</div>
								</div>
								<div class="col-auto pl-0">
									<i class="fi fi-rr-cross cursor-pointer" v-on:click="stopLoadOngkir"></i>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="row">
								<div class="col">
									<div class="form-control h-100" v-if="!showServiceList && service">
										<div class="icheck-material-orange w-100">
											<input type="checkbox" id="insurance" v-model="formItem.insurance_type" @change="countValue()" :disabled="insuranceChangeDisabled" />
											<label for="insurance" class="form-check-label w-100">Asuransi</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group mb-2">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Atur pengirimanmu?</label>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="form-control h-100" v-bind:class="{ 'border-primary' : form.order_type == 0 }" v-bind:style="{ backgroundColor: form.order_type == 0 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100">
											<input type="radio" id="pickup" name="order-type" v-model="form.order_type" value="0" @change="onChangeOrderType($event)" required :disabled="og_status == 1" />
											<label for="pickup" class="form-check-label w-100">Pickup</label>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="form-control h-100" v-bind:class="{ 'border-primary' : form.order_type == 1 }" v-bind:style="{ backgroundColor: form.order_type == 1 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100">
											<input type="radio" id="dropoff" name="order-type" v-model="form.order_type" value="1" @change="onChangeOrderType($event)" required :disabled="droppOffDisabled" />
											<label for="dropoff" class="form-check-label w-100">Drop Off</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="form-control bos-alert-primary lh-2 d-flex align-items-center h-100">
								<div class="row">
									<div class="col-auto d-flex align-items-center">
										<i class="fi fi-rr-info d-flex align-items-center"></i>
									</div>
									<div class="col d-flex align-items-center">
										<div class="text-md" v-if="form.order_type == 0">
											Paket kiriman kamu dijemput sama kurir di alamat pengiriman yang sudah kamu lengkapi.
										</div>
										<div class="text-md" v-else>
											Paket kiriman diantar sendiri sama kamu ke agen ekspedisi terdekat.
											<span class="cursor-pointer" style="color: #4fa2ef;font-weight: 500;" v-on:click="showItemDropOff"> Lihat Selengkapnya</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" v-if="form.order_type == 0">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Mau dijemput pakai apa?</label>
								</div>
							</div>
							<div class="row" style="row-gap: 15px;">
								<div class="col">
									<div class="form-control border-primary py-3 px-3 h-100" v-bind:class="{ 'bg-primary' : form.pickup_via == 0 }" style="border-width: 2px;">
										<input type="radio" id="motor" name="pickup-via" v-model="form.pickup_via" value="0" required :disabled="og_status == 1" hidden />
										<label for="motor" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem;" v-bind:class="{ 'text-primary' : form.pickup_via != 0 }"><i class="fi fi-rr-motorcycle d-flex align-items-center" style="font-size: larger;"></i> Motor</label>
									</div>
								</div>
								<div class="col">
									<div class="form-control border-primary py-3 px-3 h-100" v-bind:class="{ 'bg-primary' : form.pickup_via == 1 }" style="border-width: 2px;">
										<input type="radio" id="mobil" name="pickup-via" v-model="form.pickup_via" value="1" required :disabled="og_status == 1" hidden />
										<label for="mobil" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem;" v-bind:class="{ 'text-primary' : form.pickup_via != 1 }"><i class="fi fi-rr-car-side d-flex align-items-center" style="font-size: larger;"></i> Mobil</label>
									</div>
								</div>
								<div class="col">
									<div class="form-control border-primary py-3 px-3 h-100" v-bind:class="{ 'bg-primary' : form.pickup_via == 2 }" style="border-width: 2px;">
										<input type="radio" id="truk" name="pickup-via" v-model="form.pickup_via" value="2" required :disabled="og_status == 1" hidden />
										<label for="truk" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem;" v-bind:class="{ 'text-primary' : form.pickup_via != 2 }"><i class="fi fi-rr-truck-side d-flex align-items-center" style="font-size: larger;"></i> Truk</label>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" v-if="form.order_type == 0">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Paketmu diambil jam berapa?</label>
								</div>
							</div>
							<select class="form-control" id="pickup-time" name="pickup-time" v-model="form.pickup_time" :required="submitType != 'draft' && form.order_type == 0" :disabled="pickupTimeDisabled">
								<optgroup v-for="group in pickupTimeOpt" :label="group.label">
									<option v-for="time in group.data" v-bind:value="time.id" :disabled="time.disabled">
										{{ time.label }}
									</option>
								</optgroup>
							</select>
						</div>
						<div class="form-group mb-0">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Catatan tambahan (opsional)</label>
								</div>
							</div>
							<textarea class="form-control py-2" id="note" name="note" v-model="form.note" placeholder="Tulis Catatan Tambahan" :disabled="og_status == 1" style="min-height: 70px"
								onkeydown="return (event.ctrlKey || event.altKey 
								|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57 && event.shiftKey==true)
								|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48 && event.shiftKey==true)
								|| (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false)
								|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"></textarea>
						</div>
					</div>
				</div>
				<div class="card border rounded mb-3" v-if="item.codStatus || (formItem.nilai_ongkir && (!item.codStatus || (item.codStatus && formItem.feeCod)))">
					<div class="card-body">
						<div class="form-group mb-2" v-if="item.codStatus">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Nilai COD <span class="text-primary">(opsional)</span></label>
								</div>
							</div>
							<currency-input class="form-control" currency="IDR" @keyup="countValue($event)"
								v-model="item.codValue" v-bind:class="{ 'is-invalid': codValueErr }"
								:precision="0" name="codValue[]" aria-placeholder="Rp" :disabled="!service" />
							<div class="invalid-feedback text-right" v-if="errors[0]">{{ errors[0].message }}</div>
						</div>
						<div class="form-group mb-0" v-if="item.codStatus">
							<div class="form-control bos-alert-primary lh-2 d-flex align-items-center h-100">
								<div class="row">
									<div class="col-auto d-flex align-items-center">
										<i class="fi fi-rr-info d-flex align-items-center"></i>
									</div>
									<div class="col d-flex align-items-center">
										<div class="text-md">
											Nilai COD = Total nilai barang ditambahkan ongkir atau yang ditagihkan ke customer
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group mb-0" :class="{ 'mt-3' : item.codStatus }" v-if="formItem.nilai_ongkir && (!item.codStatus || (item.codStatus && formItem.feeCod))">
							<label class="mb-2">Rincian Pembayaran</label>
							<div v-if="item.codStatus == 0" class="d-flex flex-column mt-1" style="gap: 6px">
								<div class="d-flex justify-content-between">
									<span>Berat Paket {{ item.weight  < item.weight_volume ? '(Volumetrik)' : '' }}</span>
									<span>{{item.weight_volume}} Kg</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai Paket</span>
									<span>Rp {{ formatPrice(item.item_value) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span :class="{ 'cursor-pointer' : item.codStatus }" @click="toggleDetail">Ongkir <span v-if="item.codStatus">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="item.codStatus"></i></span>
									<span>Rp {{ formatPrice(item.codStatus ? formItem.nilai_ongkir + formItem.feeCod : formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="item.codStatus && showDetail">
									<span>Ongkir + PPN</span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="item.codStatus && showDetail">
									<span>COD Fee + PPN</span>
									<span>Rp {{ formatPrice(formItem.feeCod) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Biaya Asuransi</span>
									<span>Rp {{ formatPrice(formItem.insurance_fee) }}</span>
								</div>
								<hr class="m-0 border-secondary" style="border-style: dashed;border-width: thin;" />
								<div class="d-flex justify-content-between">
									<span>{{ item.codStatus ? 'Nilai yang kamu terima' : 'Total' }}</span>
									<span class="font-weight-bold" :style="{ 'color' : ((item.codStatus && formItem.totalReceived > 0) || (!item.codStatus && formItem.nilai_ongkir + formItem.insurance_fee > 0) || (availableCost == true) ? '#00ab4f' : '#ab0007') }">Rp {{ formatPrice(item.codStatus ? (item.codValue ? formItem.totalReceived : item.item_value) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
							<div v-else-if="item.codStatus && item.codValue" class="d-flex flex-column mt-1" style="gap: 6px">
								<div class="d-flex justify-content-between">
									<span>Berat Paket {{ item.weight  < item.weight_volume ? '(Volumetrik)' : '' }}</span>
									<span>{{item.weight_volume}} Kg</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai COD</span>
									<span>Rp {{ formatPrice(item.codValue) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span :class="{ 'cursor-pointer' : item.codStatus }" @click="toggleDetail">Ongkir <span v-if="item.codStatus">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="item.codStatus"></i></span>
									<span>Rp {{ formatPrice(item.codStatus ? formItem.nilai_ongkir + formItem.feeCod : formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="item.codStatus && showDetail">
									<span>Ongkir + PPN</span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="item.codStatus && showDetail">
									<span>COD Fee + PPN</span>
									<span>Rp {{ formatPrice(formItem.feeCod) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Biaya Asuransi</span>
									<span>Rp {{ formatPrice(formItem.insurance_fee) }}</span>
								</div>
								<hr class="m-0 border-secondary" style="border-style: dashed;border-width: thin;" />
								<div class="d-flex justify-content-between">
									<span>{{ item.codStatus ? 'Nilai yang kamu terima' : 'Total' }}</span>
									<span class="font-weight-bold" :style="{ 'color' : ((item.codStatus && formItem.totalReceived > 0) || (!item.codStatus && formItem.nilai_ongkir + formItem.insurance_fee > 0) || (availableCost == true) ? '#00ab4f' : '#ab0007') }">Rp {{ formatPrice(item.codStatus ? (item.codValue ? formItem.totalReceived : item.item_value) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
							<div v-else class="d-flex flex-column mt-1" style="gap: 6px">
								<div class="d-flex justify-content-between">
									<span>Berat Paket {{ item.weight  < item.weight_volume ? '(Volumetrik)' : '' }}</span>
									<span>{{item.weight_volume}} Kg</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai Paket</span>
									<span>Rp {{ formatPrice(item.item_value) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span :class="{ 'cursor-pointer' : item.codStatus }" @click="toggleDetail">Ongkir <span v-if="item.codStatus">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="item.codStatus"></i></span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir + Math.round(((item.item_value + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) + (((item.item_value + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) * (formItem.ppnCod / 100)))) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="item.codStatus && showDetail">
									<span>Ongkir + PPN</span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="item.codStatus && showDetail">
									<span>COD Fee + PPN</span>
									<span>Rp {{ formatPrice(((item.item_value + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) + (((item.item_value + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) * (formItem.ppnCod / 100)))}}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Biaya Asuransi</span>
									<span>Rp {{ formatPrice(formItem.insurance_fee) }}</span>
								</div>
								<hr class="m-0 border-secondary" style="border-style: dashed;border-width: thin;" />
								<div class="d-flex justify-content-between">
									<span>{{ item.codStatus ? 'Nilai yang kamu terima' : 'Total' }}</span>
									<span class="font-weight-bold" :style="{ 'color' : ((item.codStatus && formItem.totalReceived > 0) || (!item.codStatus && formItem.nilai_ongkir + formItem.insurance_fee > 0) || (availableCost == true) ? '#00ab4f' : '#ab0007') }">Rp {{ formatPrice(item.codStatus ? (item.codValue ? formItem.totalReceived : item.item_value) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
							<div class="mt-2 pt-1">
								<div class="d-flex justify-content-between font-weight-bold">
									<span>{{ item.codStatus ? 'Ditagihkan ke customer' : 'Total Pembayaran' }}</span>
									<span>Rp {{ formatPrice(item.codStatus ? (item.codValue > 0 ? item.codValue : ((item.item_value + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * (formItem.fee_cod / 100)))))) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card border rounded mb-3">
					<div class="card-body">
						<div class="form-group mb-2">
							<div class="form-control bos-alert-warning lh-2 d-flex align-items-center h-100">
								<div class="row">
									<div class="col-auto d-flex align-items-center">
										<i class="fi fi-rr-info d-flex align-items-center"></i>
									</div>
									<div class="col d-flex align-items-center">
										<div class="text-md">
											Dengan membuat pesanan, saya menyetujui <a href="//boscod.com/syarat-ketentuan-boscod/" target="_blank" class="text-primary">Syarat & Ketentuan</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group mb-0" v-if="og_status != 1">
							<div v-if="this.group_id == null && this.$route.params.id != null && job_status != 3" class="row">
								<div class="col">
									<button type="button" @click="cancelDraft"
										class="btn btn-outline-primary btn-block py-3">
										Batalkan
										<span v-if="submitting" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
								<div class="col">
									<button type="submit" @click="submitType='editDraft'" id="editDraft"
										class="btn btn-primary btn-block py-3" :disabled="disableSubmit">
										Simpan
										<span v-if="submitting" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
							</div>
							<div v-else class="row">
								<div class="col">
									<button type="submit" @click="submitType='resubmit'" id="resubmit"
										class="btn btn-outline-primary btn-block py-3" :disabled="disableSubmit">
										Tambah Pesanan
										<span v-if="submitting" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
								<div class="col">
									<button type="submit" @click="submitType='submit'" id="submit"
										class="btn btn-primary btn-block py-3" :disabled="disableSubmit">
										Proses Sekarang
										<span v-if="submitting" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="saveItem">
						<div class="modal-header">
							<h4 class="modal-title font-weight-bold">Detail Paket</h4>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<i class="fi fi-rr-cross-small"></i>
							</button>
						</div>
						<div class="modal-body">
							<div class="row custom-tab mb-3 border-bottom">
								<div class="col-6 px-0 cursor-pointer" :class="(tempItem.item_type == 'new' ? 'active text-dark' : 'text-secondary')" @click="tempItem.item_type = 'new'">
									<h6 class="text-center mb-0 pb-3 font-weight-bold">Tambah Detail Produk</h6>
								</div>
								<div class="col-6 px-0 cursor-pointer" :class="(tempItem.item_type == 'old' ? 'active text-dark' : 'text-secondary')" @click="klikCatalog()">
									<h6 class="text-center mb-0 pb-3 font-weight-bold">Katalog Produk</h6>
								</div>
							</div>
							<div class="row" v-if="tempItem.item_type == 'new'">
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Nama Produk <span class="text-primary">(wajib diisi)</span></label>
									<input id="item_name" class="form-control" v-model="tempItem.item_name" type="text"
										required="required" name="item_name[]" placeholder="Masukkan Nama Produk"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))" />
								</div>
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Kategori <span class="text-primary">(wajib diisi)</span></label>
									<select id="item_category" class="form-control" v-model="tempItem.item_category" name="item_category[]" required="required" @click="loadCategory()" @change="setCategoryName()" >
										<option :value="null" selected disabled>Pilih kategori</option>
										<option v-for="category in categoryOptions" v-bind:key="category.id" v-bind:value="category.id">
											{{ category.name }}
										</option>
									</select>
								</div>
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Harga Produk <span class="text-primary">(wajib diisi)</span></label>
									<currency-input class="form-control" currency="IDR" @keydown="hitungBiaya($event)"
										v-model="tempItem.item_value" v-bind:class="{ 'is-invalid': false }"
										:precision="0" :valueRange="{ 'min': 1 }" name="item_value[]" placeholder="Rp" required="required" />
									<div style="margin-top: 5px; margin-left: 2px">
										<span class="text-xs text-primary" >Pastikan harga produk sesuai aslinya!</span>
									</div>
									<div class="invalid-feedback" max="2500000" id="feedback-1" v-if="errors[0]">
										<h6>{{ errors[0].message }}</h6>
									</div>
								</div>
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Berat <span class="text-primary">(wajib diisi)</span></label>
									<div class="input-group">
										<input class="form-control border-right-0 mask" v-model="tempItem.weight" type="number" min="0.1" step="0.1"
											required="required" name="weight[]" placeholder="Cth: 0,1 - 99"
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==190 && event.shiftKey==false) 
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))" />
										<div class="input-group-append">
											<div class="input-group-text text-primary font-weight-bold pl-0 border-left-0" style="background-color: #fff;">Kg</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal">Panjang</label>
									<div class="input-group">
										<input class="form-control border-right-0" v-model="tempItem.length" type="number" step="1"
											placeholder="Cth: 10" name="length[]"
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))" />
										<div class="input-group-append">
											<div class="input-group-text text-primary font-weight-bold pl-0 border-left-0" style="background-color: #fff;">Cm</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal">Lebar</label>
									<div class="input-group">
										<input class="form-control border-right-0" v-model="tempItem.width" type="number" step="1"
											placeholder="Cth: 10" name="width[]"
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))" />
										<div class="input-group-append">
											<div class="input-group-text text-primary font-weight-bold pl-0 border-left-0" style="background-color: #fff;">Cm</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal">Tinggi</label>
									<div class="input-group">
										<input class="form-control border-right-0" v-model="tempItem.height" type="number" step="1"
											placeholder="Cth: 10" name="height[]"
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))" />
										<div class="input-group-append">
											<div class="input-group-text text-primary font-weight-bold pl-0 border-left-0" style="background-color: #fff;">Cm</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal"> Qty</label>
									<button type="button" class="btn" v-tooltip="{ content: 'Isi barang dalam 1 koli / paket' }" style="padding: 6px 4px"><i class=" fas fa-info-circle"></i></button>
									<div class="input-group">
										<input class="form-control border-right-0" v-model="tempItem.item_qty" type="number" step="1"
											placeholder="Cth: 1" name="item_qty[]"
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))" />
										<div class="input-group-append">
											<div class="input-group-text text-primary font-weight-bold pl-0 border-left-0" style="background-color: #fff;"></div>
										</div>
									</div>
								</div>
								<div class="col-md-12 mb-3">
									<div class="p-2 bg-light rounded">
										<div class="row">
											<div class="col">
												<div>Tambah produk ke katalog</div>
												<div class="text-xs">Simpan detail produkmu untuk pesanan yang lainnya</div>
											</div>
											<div class="col-auto d-flex align-items-center h2 mb-0 cursor-pointer" @click="tempItem.item_save = !tempItem.item_save">
												<i class="fi d-flex align-items-center" :class="(tempItem.item_save ? 'fi-rr-toggle-on text-primary' : 'fi-rr-toggle-off text-secondary')"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12 mb-3">
									<div class="form-control bos-alert-warning lh-1 d-flex align-items-center h-100">
										<div class="row">
											<div class="col-auto d-flex align-items-center">
												<i class="fi fi-rr-info d-flex align-items-center"></i>
											</div>
											<div class="col d-flex align-items-center">
												<div class="text-md">
													Tentang perhitungan volumetrik. <a href="https://boscod.com/volumetric/" target="_blank" class="text-primary">Selengkapnya</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col">
									<button type="button" class="btn btn-block btn-outline-primary h-100 py-3" data-dismiss="modal">Batalkan</button>
								</div>
								<div class="col">
									<button type="submit" class="btn btn-block btn-primary h-100 py-3" @click="submitItemType='append'">Simpan</button>
								</div>
							</div>
							<div v-else-if="tempItem.item_type == 'old'">
								<div class="form-group">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text pr-0 rounded" style="background-color: #fff;"><i class="fi fi-rr-search"></i></div>
										</div>
										<input type="text" class="form-control border-left-0" v-model="item.find_catalogue" placeholder="Cari Produk" alt="🔎︎" v-on:keyup="findCatalogue()">
									</div>
								</div>
								<div class="d-flex flex-column overflow-auto" style="max-height: 450px;gap: 1rem;">
									<div v-if="!catalogueOptions.length" class="form-control h-100 py-3">
										<div v-if="loadingCatalogue" class="overlay d-flex justify-content-center pt-5"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
										<div class="d-flex flex-column" style="gap: 6px">
											<div>&nbsp;</div>
											<div>&nbsp;</div>
											<div>&nbsp;</div>
											<div>&nbsp;</div>
										</div>
									</div>
									<div v-else class="form-control d-flex justify-content-between align-items-center h-100 py-3" v-for="catalogue in catalogueOptions" style="min-height: auto;">
										<div class="d-flex flex-column" style="gap: 6px">
											<div style="font-weight: bolder;">{{ catalogue.name }}</div>
											<div style="font-weight: bold;">Rp {{ formatPrice(catalogue.price) }} <sup><sub style="opacity: 25%;">&#9679;</sub></sup> {{ catalogue.weight }} Kg <sup><sub style="opacity: 25%;">&#9679;</sub></sup> {{ catalogue.category_name }}</div>
											<div>{{ catalogue.length }}cm x {{ catalogue.width }}cm x {{ catalogue.height }}cm</div>
										</div>
										<div class="d-none d-sm-flex mx-5">&nbsp;</div>
										<button type="button" class="btn btn-sm btn-warning text-light" style="margin-right: 6px; background-color: red;" v-if="true" v-on:click="deletedItem(catalogue.id, catalogue.name)">
											Delete&nbsp;Produk
										</button>
										<button type="button" class="btn btn-sm btn-warning text-light" v-if="true" v-on:click="onSelectCatalogue(catalogue.id, catalogue.category_id, catalogue.category_name)">
											Pilih&nbsp;Produk
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="formDialogKurir" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="saveCourier">
						<div class="modal-header">
							<h5 class="modal-title">Pengaturan Kurir Pengiriman</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<i class="fi fi-rr-cross-small"></i>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group" v-for="(list, cid) in dataCustomCourier">
								<div class="row">
									<div class="col">
										<div class="form-control h-100" :class="{ 'border-primary' : dataCustomCourier[cid].status }" style="border-width: 2px;">
											<div class="icheck-material-orange d-flex flex-row-reverse justify-content-between align-items-center w-100">
												<input type="checkbox" :id="list.prefix" v-on:click="changeCourier($event)" :value="list.courier_id" :checked="list.status == '0' ? false : true" v-on:input="list.courier_id = $event.target.value" />
												<label :for="list.prefix" class="form-check-label"></label>
												<label :for="list.prefix" class="d-flex align-items-center w-100">{{ list.nama }}</label>
												<div class="d-flex align-items-center justify-content-center" style="height: 35px;">
													<img :src="getKurirLogo(list.prefix)" style="max-width: 100%;max-height: 60px;" />
												</div>
											</div>

											<!-- <div class="form-control mb-3" style="height: fit-content;border-width: 1.5px; border-color: rgb(169, 169, 169); color: grey;" v-if="list.prefix == 'IDX' && list.flat_status == 'true'">
												<div class="row" style="display: flex; align-items: center; padding: 5px;">
													<div class="col-4 font-weight-bold">ID Express Flat</div>
													<div class="col-6" style="text-align: right;">Klik untuk <span v-if="aktifkan_flat_idx">non</span>aktifkan</div>
													<div class="col-2">
														<label class="switch">
															<input type="checkbox" id="flat_status" :checked="list.shiper_meta_flat" v-on:click="changeFlat($event)" :value="list.courier_id">
															<span class="slider round"></span>
														</label>
													</div>
												</div>
												<div style="padding: 5px;">
													<div class="font-weight-bold" style="border-top: 1px solid rgb(169, 169, 169); ;padding-top: 5px;"></div>
													<div class="font-weight-bold" style="padding-top: 5px;">Info Pengiriman</div>
													<ul class="mb-0" style="padding-inline-start: 20px;">
														<li style="padding-top: 5px;">Berat diatas 500 gram (STD), diskon 20%</li>
														<li style="padding-top: 5px;">Berat dibawah 500 gram</li>
														<li style="padding-top: 5px;">Kirim dari jawa dan ke jawa dengan jangkauan 100% cuma <strong>Rp5.000/Kg</strong></li>
													</ul>
												</div>
											</div> -->
										</div>
									</div>
								</div>
							</div>
							<div class="row pt-3">
								<div class="col">
									<button type="button" class="btn btn-block btn-outline-primary font-weight-bold py-3" data-dismiss="modal" :disabled="submitting">
										Batal <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									</button>
								</div>
								<div class="col">
									<button type="submit" class="btn btn-block btn-primary font-weight-bold py-3" :disabled="submitting">
										Simpan <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="formDialogDropOff" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Tentang Pengiriman Drop Off</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row" style="align-items: center;">
							<!-- <div class="col-md-1" >
								<h1 style="margin-left: 15px;">.</h1>
							</div>
							<div class="col-md-11">
								JNE hanya bisa drop off melalui kantor cabang, tidak bisa di counter
							</div> -->
							<div class="col-md-1" >
								<h1 style="margin-left: 15px;">.</h1>
							</div>
							<div class="col-md-11">
								SAP hanya bisa drop off melalui kantor cabang, tidak bisa di counter
							</div>
							<div class="col-md-1" >
								<h1 style="margin-left: 15px;">.</h1>
							</div>
							<div class="col-md-11">
								IDX bisa melalui counter atau AlfaMart
							</div>
							<div class="col-md-1" >
								<h1 style="margin-left: 15px;">.</h1>
							</div>
							<div class="col-md-11">
								Sicepat bisa melalui counter
							</div>
							<div class="col-md-1" >
								<h1 style="margin-left: 15px;">.</h1>
							</div>
							<div class="col-md-11">
								JNT bisa melalui counter terdekat
							</div>
						</div>
					</div>
					<div class="modal-footer" style="align-items: center; justify-content: center; text-align: center;">
						<button type="button" class="btn btn-primary" data-dismiss="modal" style="width: 100%;">Mengerti</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="formReceiver" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="saveReceiver">
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">{{ form.receiver_type == 'new' ? 'Tambah Alamat Penerima' : form.receiver_type == 'old' ? 'Riwayat Penerima' : '' }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<i class="fi fi-rr-cross-small"></i>
							</button>
						</div>
						<div class="modal-body">
							<div class="row custom-tab mb-3 border-bottom">
								<div class="col-6 px-0 cursor-pointer" :class="(form.receiver_type == 'new' ? 'active text-dark' : 'text-secondary')" @click="form.receiver_type = 'new'">
									<h6 class="text-center mb-0 pb-3 font-weight-bold">Tambah Alamat Penerima</h6>
								</div>
								<div class="col-6 px-0 cursor-pointer" :class="(form.receiver_type == 'old' ? 'active text-dark' : 'text-secondary')" @click="form.receiver_type = 'old'; findBuyer()">
									<h6 class="text-center mb-0 pb-3 font-weight-bold">Riwayat Penerima</h6>
								</div>
							</div>
							<div class="row" v-if="form.receiver_type == 'new'">
								<div class="col-md-12 form-group">
									<label class="control-label">Nama Penerima</label>
									<input type="hidden" name="row_id" value />
									<input id="destName" class="form-control" v-model="receiver.destName" type="text" placeholder="Cth: Rajib Wijaya"
										minlength="3" required="required" name="destName[]"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==191 && event.shiftKey==false) || (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91) )" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">No. Telepon</label>
									<input id="destTel" pattern="0[0-9]{7,}|62[0-9]{7,}" formnovalidate class="form-control"
										v-model="receiver.destTel" type="text" required="required" name="destTel[]" placeholder="Cth: 085XXXXXXXXX"
										minlength="10" maxlength="16" v-bind:class="{ 'is-invalid': destTelErr }"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (85<event.keyCode && event.keyCode<87))" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Kecamatan atau Kota</label>
									<v-select required label="lokasi" name="addr_id[]" :filterable="false"
										:options="addrOptions" v-model="destCity" :reduce="(opt) => opt.id"
										@search="onSearchDestCity" @input="onChangeDestCity">
										<template slot="no-options">Cari Kecamatan atau Kota</template>
										<template slot="option" slot-scope="option">
											<div class="d-center">{{ option.lokasi }}</div>
										</template>
										<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">{{ option.lokasi }}</div>
										</template>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!destCity"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Alamat Lengkap</label>
									<textarea class="form-control" v-model="receiver.addr_detail" required="required" placeholder="Tulis Alamat Lengkap" name="addr_detail[]" minlength="15"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57 && event.shiftKey==true)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48 && event.shiftKey==true)
										|| (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==191 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false)
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"></textarea>
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col">
									<button type="reset" class="btn btn-block btn-outline-primary font-weight-bold py-3" data-dismiss="modal">Batalkan</button>
								</div>
								<div class="col">
									<button type="submit" class="btn btn-block btn-primary font-weight-bold py-3">Konfirmasi</button>
								</div>
							</div>
							<div v-else-if="form.receiver_type == 'old'">
								<div class="form-group">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text pr-0 rounded" style="background-color: #fff;"><i class="fi fi-rr-search"></i></div>
										</div>
										<input type="text" class="form-control border-left-0" v-model="receiver.find_buyer" placeholder="Cari Alamat" alt="🔎︎" v-on:keyup="findBuyer()">
									</div>
								</div>
								<div class="d-flex flex-column overflow-auto" style="max-height: 450px;gap: 1rem;">
									<div v-if="!buyerOptions.length" class="form-control h-100 py-3">
										<div v-if="loadingBuyer" class="overlay d-flex justify-content-center pt-5"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
										<div class="d-flex flex-column" style="gap: 6px">
											<div>&nbsp;</div>
											<div>&nbsp;</div>
											<div>&nbsp;</div>
											<div>&nbsp;</div>
										</div>
									</div>
									<div v-else class="form-control d-flex justify-content-between align-items-center h-100 py-3" v-for="buyer in buyerOptions" style="min-height: auto;">
										<div class="d-flex flex-column text-break" style="gap: 6px">
											<div class="font-weight-bold">{{ buyer.receiver_name }}</div>
											<div>{{ buyer.receiver_phone }}</div>
											<div>{{ buyer.receiver_address }}</div>
											<div>{{ buyer.receiver_kecamatan }}, {{ buyer.kota }}, {{ buyer.receiver_postcode }}</div>
										</div>
										<div class="d-none d-sm-flex mx-5">&nbsp;</div>
										<button type="button" class="btn btn-sm btn-warning text-light" v-if="buyer.id != receiver.addr_id" v-on:click="onSelectBuyer(buyer.id)">
											Pilih&nbsp;Alamat
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="draftCourier" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="submitDraftForm">
						<div class="modal-header">
							<h5 class="modal-title">Pilih Kurir Pengirimanmu</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<i class="fi fi-rr-cross-small"></i>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group" v-for="(list, cid) in dataCustomCourier">
								<div class="row">
									<div class="col">
										<div class="form-control h-100" :class="{ 'border-primary' : dataCustomCourier[cid].status }" style="border-width: 2px;">
											<div class="icheck-material-orange d-flex flex-row-reverse justify-content-between align-items-center w-100">
												<input type="radio" name="courier-draft" :id="list.prefix + '-' + list.courier_id" v-model="form.courier_id" :value="list.courier_id" :checked="form.courier_id == list.courier_id" v-on:input="form.courier_id = $event.target.value" required />
												<label :for="list.prefix + '-' + list.courier_id" class="form-check-label"></label>
												<label :for="list.prefix + '-' + list.courier_id" class="d-flex align-items-center w-100">{{ list.nama }}</label>
												<div class="d-flex align-items-center justify-content-center" style="height: 35px;">
													<img :src="getKurirLogo(list.prefix)" style="max-width: 100%;max-height: 60px;" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row pt-3">
								<div class="col">
									<button type="reset" @click="form.courier_id = 0" class="btn btn-block btn-outline-primary font-weight-bold py-3" data-dismiss="modal">Batal</button>
								</div>
								<div class="col">
									<button type="submit" class="btn btn-block btn-primary font-weight-bold py-3">Simpan</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<Warehouse :show="show" @input="onChangeWarehouse" v-model="sender" />
	</section>
</template>
<script>
import Vue from "vue";
import { authFetch, formatCurrency, charsRemover } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import Warehouse from "@/dialog/Warehouse";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "dropzone/dist/dropzone.css";
import "vue-select/dist/vue-select.css";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import VTooltip from 'v-tooltip';
import "icheck-material";
import "daterangepicker/daterangepicker.css";
import 'v-tooltip/dist/v-tooltip.css';
import { getAnalytics, logEvent } from "firebase/analytics";
import StarRating from 'vue-star-rating';

const analytics = getAnalytics();

Vue.use(ToggleButton);
Vue.use(VTooltip, {
	themes: {
		'boscod': {
			$extend: 'tooltip',
			triggers: [
					'hover',
					'focus',
					'click',
					'touch',
			],
			placement: 'auto',
		},
	},
});

export default {
	data() {
		return {
			optPickupTime: {
				minDate: moment()
					.add(4, "h")
					.toDate(),
				format: "DD/MM/YYYY HH:mm"
			},
			optPickupEnd: {
				minDate: moment()
					.add(2, "d")
					.add(5.5, "h")
					.toDate(),
				format: "DD/MM/YYYY HH:mm"
			},
			ongkirInProgres: [],
			validasi_wa: false,
			service_category: 0,
			errors: [],
			signals: null,
			job_status: null,
			controllerRef: null,
			shouldDisable: false,
			codValueErr: false,
			destTelErr: false,
			totalCourier: [],
			addrOptions: [],
			buyerOptions: [],
			catalogueOptions: [],
			categoryOptions: [],
			listCourier: [],
			loading: false,
			loadingBuyer: false,
			loadingCatalogue: false,
			roles: "",
			groups: [],
			pickTimeExcept:["SAP", "JNT", "JNTCargo"],
			ServicePickTimeExcept:["ANTERAJASD"],
			ServiceCodExcept:["ANTERAJASD"],
			insuranceExcept:["ANTERAJA", "JNE"],
			show: false,
			method: "POST",
			verifikasiKtp: 0,
			listCourierSelect: [],
			form: {
				// pickup_time: moment().add(4, "h").toDate(),
				pickup_via: "0",
				note: "",
				courier_id: "0",
				code: "",
				shipper_addr_id: "",
				sender_name: "",
				sender_addr_id: "",
				sender_address: "",
				sender_postcode: "",
				sender_addr_code: "",
				sender_phone: "",
				receiver_type: "new",
				submit: "",
				order_no : "",
				order_type: "0",
				pickupend_on: moment()
					.add(2, "d")
					.add(5.5, "h")
					.toDate()
			},
			formItem: {
				//order
				id: "",
				destName: "",
				destTel: "",
				destCity: "",
				destCode: "",
				addr_id: "",
				addr_detail: "",
				item_name: "",
				item_category: null,
				category_name: "",
				item_value: 0,
				weight: null,
				length: "",
				width: "",
				height: "",
				item_qty: 1,
				note: "",
				order_code: "",
				no_resi: "",
				nilai_ongkir: 0,
				insurance_type: "1",
				codValue: 0,
				codStatus: true,
				kodepos: "",
				service_code: "",
				ppn_ongkir_inc: 1
			},
			destCity: "",
			receiver: { confirm: false },
			tempItem: {
				item_type: 'new',
				item_name: "",
				item_category: null,
				category_name: "",
				item_value: null,
				weight: null,
				length: 0,
				width: 0,
				height: 0,
				item_save: false,
				confirm: false,
				codStatus: true,
				codValue: 0,
				weight_volume : 0,
				item_qty : 1,
				total_biaya: 0
			},
			item: {
				item_type: 'new',
				item_name: "",
				item_category: null,
				category_name: "",
				item_value: 0,
				weight: null,
				length: 0,
				width: 0,
				height: 0,
				item_save: false,
				confirm: false,
				codStatus: true,
				codValue: 0,
				weight_volume : 0
			},
			resultOngkir: [],
			dataCustomCourier: [],
			dataPayload: [],
			service: {
				id: "",
				kurir: "",
				service: "",
				serviceName: "",
				etd_from: 0,
				etd_thru: 0,
				price: 0,
				maxPrice: 0,
				disc: 0,
				ppn: 0,
				ppn_ongkir: 0,
				ppn_ongkir_inc: 0,
				ins: 0,
				insmin: 0,
				insfee: 0,
				feecod: 0,
			},
			showServiceList: false,
			ongkir_satuan: 0,
			availableCost: false,
			loadingOngkir: false,
			submitting: false,
			og_status: "",
			sender: {},
			dropship: {
				checker: false,
				senderName: '',
				senderTel: '',
			},
			showDetail: false,
			customSwal: {},
			submitType: '',
			droppOffDisabled: true,
			aktifkan_flat_idx: false,
			retur_exist: false,
			id_receiver_from_ongkir : null,
			group_id : null,
			params_id : null
		};
	},
	created: function() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Create Order Single'
		});
		// this.loadCourier();
		this.getDataCourier();
		if (this.$route.params.id) {
			this.params_id = this.$route.params.id;
			this.loadOrderGroup(this.$route.params.id);
			this.shouldDisable = true;
		} else {
			if (!this.$route.params.data_from_ongkir){
				this.loadAddress();
			} else {
				this.loadAddress(this.$route.params.data_from_ongkir.shipper_address_id);
			}
		}

		var now = moment();
		var next1d = moment().add(1,'days');
		var next2d = moment().add(2,'days');
		this.pickupTimeOpt = [
			{
				label: "Hari Ini, " + moment().format("DD/MM/YYYY"),
				data: [
					{ id: now.format("YYYY-MM-DD 08:00:00"), label: "08:00 - 12:00", disabled: moment() > moment("10:00", 'HH:mm') },
					{ id: now.format("YYYY-MM-DD 12:00:00"), label: "12:00 - 16:00", disabled: moment() > moment("14:00", 'HH:mm') },
					{ id: now.format("YYYY-MM-DD 16:00:00"), label: "16:00 - 20:00", disabled: moment() > moment("18:00", 'HH:mm') },
				],
			},
			{
				label: "Besok, " + next1d.format("DD/MM/YYYY"),
				data: [
					{ id: next1d.format("YYYY-MM-DD 08:00:00"), label: next1d.format("DD/MM/YYYY") + " 08:00 - 12:00" },
					{ id: next1d.format("YYYY-MM-DD 12:00:00"), label: next1d.format("DD/MM/YYYY") + " 12:00 - 16:00" },
					{ id: next1d.format("YYYY-MM-DD 16:00:00"), label: next1d.format("DD/MM/YYYY") + " 16:00 - 20:00" },
				],
			},
			{
				label: next2d.format("dddd, DD/MM/YYYY"),
				data: [
					{ id: next2d.format("YYYY-MM-DD 08:00:00"), label: next2d.format("DD/MM/YYYY") + " 08:00 - 12:00" },
					{ id: next2d.format("YYYY-MM-DD 12:00:00"), label: next2d.format("DD/MM/YYYY") + " 12:00 - 16:00" },
					{ id: next2d.format("YYYY-MM-DD 16:00:00"), label: next2d.format("DD/MM/YYYY") + " 16:00 - 20:00" },
				],
			},
		];
		this.setPickupTime();
		window.onbeforeunload = this.beforeGo;
	},
	watch: {
		$route(to, from) {},
		ongkirInProgres: function(val) {
			//do something when the data changes.
			if (Object.keys(this.ongkirInProgres).length==Object.keys(this.listCourier).length==1) {
				this.signals=null;
			}
		},
		receiver: {
			handler(val) {
				this.receiver.addr_detail = charsRemover(val.addr_detail);
			},
			deep: true
		},
	},
	computed:{
		disableSubmit: function() {
			return (this.submitting || !this.service || this.showServiceList || !this.availableCost || this.codValueErr);
		},
		insuranceChangeDisabled: function() {
			if (this.og_status == 1 || this.insuranceExcept.includes(this.service.kurir) && this.item.codStatus || this.service.kurir == 'JNE') {
				this.formItem.insurance_type = true;
				return true;
			} else {
				return false;
			}
		},
		codDisabled: function() {
			if (this.group_id == null && this.params_id != null && this.job_status != 3){
				return false;
			} else {
				if (this.og_status == 1 || this.ServiceCodExcept.includes(this.service.service) || this.verifikasiKtp == 0) {
					this.item.codStatus = false;
					this.tempItem.codStatus = false;
					return true;
				} else {
					// this.item.codStatus = true;
					// this.tempItem.codStatus = true;
					return false;
				}
			}
		},
		pickupTimeDisabled: function() {
			return (this.og_status == 1 || this.pickTimeExcept.includes(this.service.kurir) || this.ServicePickTimeExcept.includes(this.service.service));
		},
	},
	methods: {
		loadCategory() {
			if (this.categoryOptions.length == 0 || this.categoryOptions.length == 1) {
				authFetch("/catalog/category")
				.then((res) => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
				})
				.then((js) => {
					this.categoryOptions = js.data;
				});
			}
		},
		setPickupTime() {
			var now = moment();
			var tomorrow = moment().add(1,'days');
			if (moment() < moment("08:00", 'HH:mm')) {
				this.form.pickup_time = now.format("YYYY-MM-DD 08:00:00");
				this.form.pickupend_on = now.format("YYYY-MM-DD 12:00:00");
			} else if (moment() < moment("12:00", 'HH:mm')) {
				this.form.pickup_time = now.format("YYYY-MM-DD 12:00:00");
				this.form.pickupend_on = now.format("YYYY-MM-DD 16:00:00");
			} else if (moment() < moment("16:00", 'HH:mm')) {
				this.form.pickup_time = now.format("YYYY-MM-DD 16:00:00");
				this.form.pickupend_on = now.format("YYYY-MM-DD 20:00:00");
			} else {
				this.form.pickup_time = tomorrow.format("YYYY-MM-DD 08:00:00");
				this.form.pickupend_on = tomorrow.format("YYYY-MM-DD 12:00:00");
			}
		},
		beforeGo() {
			// check is items was save
			/*
			if (1) {
				return "Some task is in progress. Are you sure, you want to close?";
			}
			*/
		},
		formatPrice(data) {
			if (data == 0) {
				return 0;
			} else {
				let format = formatCurrency(data);
				return format.replaceAll(".", ",");
			}
		},
		showService(data) {
			var show = true;
			if (this.og_status == 1 || this.ServiceCodExcept.includes(data) && this.item.codStatus) {
				show = false
			} else {
				show = true
			}
			return show;
		},
		getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();

			try {
				return require(`../../assets/img/${fileName}.png`);
			} catch (error) {
			}
		},
		getImage(fileName) {
			return require(`../../assets/img/${fileName}.svg`); // the module request
		},
		toggleDetail() {
			this.showDetail = !this.showDetail;
		},
		resetFormItem() {
			this.form = {
				// pickup_time: moment().add(4, "h").toDate(),
				pickup_via: "0",
				note: "",
				courier_id: "0",
				code: this.form.code,
				shipper_addr_id: this.form.shipper_addr_id,
				sender_name: this.form.sender_name,
				sender_addr_id: this.form.sender_addr_id,
				sender_address: this.form.sender_address,
				sender_postcode: this.form.sender_postcode,
				sender_addr_code: this.form.sender_addr_code,
				sender_phone: this.form.sender_phone,
				receiver_type: "new",
				submit: "",
				order_type: "0",
				pickupend_on: moment()
					.add(2, "d")
					.add(5.5, "h")
					.toDate()
			};
			this.formItem = {
				id: "",
				destName: "",
				destTel: "",
				destCity: "",
				destCode: "",
				addr_id: "",
				addr_detail: "",
				item_name: "",
				item_category: null,
				category_name: "",
				item_value: 0,
				weight: null,
				length: "",
				width: "",
				height: "",
				item_qty: 1,
				note: "",
				order_code: "",
				no_resi: "",
				nilai_ongkir: 0,
				insurance_type: "1",
				codValue: 0,
				codStatus: true,
				kodepos: "",
				service_code: "",
				ppn_ongkir_inc: 1,
				fee_cod: 0
			};
			this.destCity = "";
			this.receiver = { confirm: false };
			this.tempItem = {
				item_type: 'new',
				item_category: null,
				category_name: "",
				item_value: null,
				weight: null,
				length: "",
				width: "",
				height: "",
				item_save: false,
				confirm: false,
				codStatus: true,
				codValue: 0,
				item_qty: 1,
				total_biaya: 0
			};
			this.item = {
				item_type: 'new',
				item_category: null,
				category_name: "",
				item_value: 0,
				weight: null,
				length: "",
				width: "",
				height: "",
				item_save: false,
				confirm: false,
				codStatus: true,
				codValue: 0,
				find_catalogue:"",
			};
			this.resultOngkir = [];
			this.listCourierSelect = [];
			this.dataCustomCourier = [];
			this.dataPayload = [];
			this.service = {
				id: "",
				kurir: "",
				service: "",
				serviceName: "",
				etd_from: 0,
				etd_thru: 0,
				price: 0,
				maxPrice: 0,
				disc: 0,
				ppn: 0,
				ppn_ongkir: 0,
				ppn_ongkir_inc: 0,
				ins: 0,
				insmin: 0,
				insfee: 0,
				feecod: 0,
			};
			this.showServiceList = false;
			this.ongkir_satuan = 0;
			this.availableCost = false;
			this.loadingOngkir = false;
			this.submitting = false;
			this.og_status = 1;
			this.sender = {};
			this.dropship = {
				checker: false,
				senderName: '',
				senderTel: '',
			};
			this.showDetail = false;
			this.submitType = '';
			this.resetService();
			this.loadAddress();
			this.setPickupTime();
			this.getDataCourier();
		},
		resetService: function() {
			this.service = {
				id: "",
				kurir: "",
				service: "",
				serviceName: "",
				etd_from: 0,
				etd_thru: 0,
				price: 0,
				maxPrice: 0,
				disc: 0,
				ppn: 0,
				ppn_ongkir: 0,
				ppn_ongkir_inc: 0,
				ins: 0,
				insmin: 0,
				insfee: 0,
				feecod: 0,
			};
			this.form.courier_id = 0;
			this.droppOffDisabled = false;
		},
		onChangeOrderType(event) {
			// if (event.target.value == 1) {
			// 	this.form.pickup_via = null;
			// } else {
			// }
			this.form.order_type = event.target.value;
		},
		onToggleChange(event) {
			// added event as second arg
			this.tempItem.codStatus = event.target.value?.toLowerCase?.() === 'true' ? true : false;
			this.formItem.codStatus = event.target.value?.toLowerCase?.() === 'true' ? true : false;
			this.item.codStatus = event.target.value?.toLowerCase?.() === 'true' ? true : false;
			this.countValue();
			this.$forceUpdate();
		},
		verifKtp() {
			this.customSwal.fire({
				html: '<img src="' + this.getImage('verified') + '" class="d-block w-75 m-auto" /><h2 class="swal2-title h4 tex-dark">Verifikasi KTP sekarang untuk dapat<br />menggunakan fitur pesanan COD</h2>',
				showDenyButton: true,
				confirmButtonText: 'Verifikasi KTP',
				denyButtonText: 'Nanti Saja',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$router.push({ path: "/verification"});
				}
			});
		},
		setSelected(value) {
			const self = this;
			self.formItem.addr_id = value;
			let s_id = self.sender.addr_id;
			let r_id = self.formItem.addr_id;
			self.loadOngkir(s_id, r_id);
		},
		loadAddress(id) {
			let route;
			if (id) {
				route = "/order/address/" + id +"/1";
			} else {
				route = "/order/address/0";
			}
			authFetch(route).then(res => {
				res.json().then(json => {
					if (json.success) {
						this.sender = json.data;
						this.addrOptions.push({
							id: json.data.addr_id,
							lokasi: json.data.kota + " / " + json.data.kecamatan,
							kodepos: json.data.kodepos,
							receiver_addr_code: json.data.kode
						});
					}
					$('.loading-overlay').removeClass('show');
				});
			});
		},
		loadOngkir(s_id, r_id) {
			const self = this;
			let courier_id = self.form.courier_id;
			let route;
			var weight = self.formItem.weight;
			if (!weight) weight = 1;
			if (s_id) {
				route = `/order/nilai_ongkir/${courier_id}/${s_id}/${r_id}/${weight}`;
			} else {
				route = "/order/nilai_ongkir/854/887";
			}
			if (self.form.shipper_id) {
				route = route + "?shipper_id=" + self.form.shipper_id;
			}
			authFetch(route).then(res => {
				res.json().then(json => {
					self.insurance = {
						percent: json.ins,
						fee: json.insfee,
						min: json.insmin
					};
					if (json.success) {
						let price = json.price == "" ? 0 : json.price;
						// let reverse = price.toString().split("").reverse().join(""),
						//	 ribuan = reverse.match(/\d{1,3}/g);
						// ribuan = ribuan.join(".").split("").reverse().join("");

						self.ongkir_satuan = price;
						self.formItem.ongkir_dasar = price * 1;
						self.formItem.feeCod = json.feecod;
						self.formItem.ppnCod = json.ppn;
						self.formItem.disc = json.disc;
						self.formItem.ins_fee = json.insfee;
						self.formItem.ins_percent = json.ins;
						self.formItem.ins_min = json.insmin;
						self.formItem.etd_from = json.etd_from;
						self.formItem.etd_thru = json.etd_thru;
						self.formItem.service_code = json.service;
						self.formItem.ppn_ongkir_inc = json.ppn_ongkir_inc;
						this.hitungBiaya();
					} else {
						self.formItem.nilai_ongkir = 0;
						self.formItem.addr_id = 0;
						Swal.fire("Proses gagal", `${json.desc}`, "error");
					}
				});
			});
		},
		getDataCourier() {
			let route = "/order/courier/courier_setting";
			try {
				authFetch(route).then(res => {
					res.json().then(json => {
						this.dataCustomCourier = json

						// for (var i = 0; i < json.length; i++){
						// 	if (parseInt(json[i].courier_id) == 1){
						// 		this.aktifkan_flat_idx = json[i].shiper_meta_flat
						// 	}
						// 	break;
						// }
					});
				});
			} catch (error) {}
		},
		async loadCourier() {
			this.resetService();
			let route = "/order/kurir";
			try {
				await authFetch(route).then(res => {
					res.json().then(json => {
						if (json.success == true) {
							this.listCourier = json.data;
							if (this.sender.id && this.receiver.confirm && this.item.confirm){
								this.mapOngkir();
							}
						} else {
							Swal.fire({
								title: "Oops...",
								icon: "error",
								text: json.message,
								showCloseButton: true
							});
						}

					});
				});
			} catch (error) {
				
			}
		},
		loadDataUserDropOff() {
			let route = "/order/shipper_tag";
			try {
				authFetch(route).then(res => {
					res.json().then(json => {
						if (json.success == true && json.info.drop_off == false){
							this.verifikasiKtp = 0;
							this.droppOffDisabled = true;
							this.item.codStatus = false;
							this.tempItem.codStatus = false;
							// this.verifKtp();
						} else {
							this.item.codStatus = true;
							this.tempItem.codStatus = true;
							this.verifikasiKtp = 1;
							this.droppOffDisabled = false;
						}
					});
				});
				
			} catch (error) {
				
			}
		},
		mapOngkir() {
			let data;
			this.ongkirInProgres =[];
			this.resultOngkir = [];
			this.listCourierSelect = [];
			// this.item.codValue = 0;
			this.formItem.nilai_ongkir = 0;
			this.formItem.feeCod = 0;
			this.formItem.ppnCod = 0;
			this.formItem.fee_cod = 0;
			this.formItem.insurance_fee = 0;
			this.formItem.totalReceived = 0;
			if (this.signals != null) {
				this.signals.abort();
			}
			const controller = new AbortController();
			this.signals = controller;

			data = this.listCourier.length > 0 ? this.listCourier.map((val,key) => {
				var data = {};
				data.addr_id = this.receiver.addr_id;
				data.sender_addr_id = this.sender.addr_id;
				data.weight = this.item.weight;
				data.courier = val.id;
				data.order_type = this.form.order_type;
				this.newLoadOngkir(data);
			}) : this.loadCourier();
		},
		newLoadOngkir(params) {
			const self = this;
			params.w=this.item.width;
			params.h=this.item.height;
			params.l=this.item.length;
			params.codStatus=this.item.codStatus;
			const courierRecommendation = 1; //idx
			//param.courier=this.listCourier;
			let data;
			let route;
			if(!this.sender.addr_id)
				return;
			data = Object.keys(params)
				.map(
					key =>
						encodeURIComponent(key) +
						"=" +
						(params[key] === false || params[key] === undefined
							? 0
							: encodeURIComponent(params[key]))
				)
				.join("&");
			route = "/order/ongkir?" + data;
			this.checking = true;
			this.loadingOngkir = true;

			authFetch(route,{signal : this.signals.signal}).then(res => {
				res.json().then(json => {
					if (params.courier == courierRecommendation) {
						const insertAt = 0;
						self.resultOngkir = [...json].concat(self.resultOngkir);
						// self.resultOngkir.push(...json);
					} else {
						self.resultOngkir.push(...json);
					}
					this.checking = false;
					this.loadingOngkir = false;
					if (Object.keys(this.resultOngkir).length == 1 && Object.keys(this.listCourier).length == 1) {
						this.selectService(0);// if only one option
					} else {
						this.showServiceList = true;
					}
					this.onToggleChangeService();
				})
				.
				finish(
					this.ongkirInProgres.push(...params.courier)
				);
			});
		},
		stopLoadOngkir: function() {
			if(this.signals!=null){
				this.signals.abort();
				this.ongkirInProgres = [];
			}
		},
		loadAllOngkir(params) {
			const self = this;
			params.w=this.item.width;
			params.h=this.item.height;
			params.l=this.item.length;
			params.codStatus=this.item.codStatus;
			//param.courier=this.listCourier;
			let data;
			let route;
			if(!this.sender.addr_id)
				return;
			this.showServiceList = true;
			data = Object.keys(params)
				.map(
					key =>
						encodeURIComponent(key) +
						"=" +
						(params[key] === false || params[key] === undefined
							? 0
							: encodeURIComponent(params[key]))
				)
				.join("&");
			route = "/order/ongkir?" + data;
			this.checking = true;
			this.loadingOngkir = true;
			authFetch(route).then(res => {
				res.json().then(json => {
					self.resultOngkir = json;
					this.checking = false;
					this.loadingOngkir = false;
					if (json.length === 1) {
						this.selectService(0);// if only one option
					}
				});
			});
		},
		onChangeOngkir(event) {
			const self = this;
			self.formItem.weight = event.target.value;
			let ongkir1 = self.ongkir_satuan;
			let nilaiOngkir = ongkir1 * Math.ceil(self.formItem.weight);

			let s_id = self.sender.addr_id;
			let r_id = self.formItem.addr_id;
			self.loadOngkir(s_id, r_id);
		},
		async loadOrderGroup(id) {
			await this.loadCategory();
			authFetch("/order/view_data_batch",{
				headers:{
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify({order_no: id}),
			}).then(res => {
				res.json().then(json => {
					if (json) {
						this.form.order_no = json.order_no;
						this.job_status = json.data.job_status;
						this.addrOptions = json.addr_options;
						this.sender = json.sender;
						this.receiver = json.receiver;
						this.tempItem = json.item;
						this.item = json.item;
						this.item.codStatus = json.item.codStatus;
						this.tempItem.codStatus = json.item.codStatus;
						if (this.item.item_category) {
							this.setCategoryName();
						}
						this.tempItem.codValue = parseInt(json.item.codValue);
						this.item.codValue = parseInt(json.item.codValue);
						this.formItem = json.form_item;
						this.formItem.codValue = parseInt(json.form_item.codValue);
						this.form = json.form;
						this.group_id = json.form.group_id;
						if (json.sender.id && json.receiver.confirm && json.item.confirm) {
							this.mapOngkir();
						}
					} else {
						Swal.fire("Proses gagal", `${js.msg}`, "error");
						this.$router.push("/order");
					}
				});
			});
		},
		onSearchDestCity(search, loading) {
			if (search.length > 3) {
				loading(true);
				//this.search(loading, search, this);
				var vm = this;
				authFetch(
					`/order/wilayah?q=${escape(search)}` + "&cid=" + this.form.courier_id
				).then(res => {
					res.json().then(json => (vm.addrOptions = json.items));
					loading(false);
				});
			}
		},
		loadReceiver(params) {
            const self = this;
            let route = "/order/wilayah_by_id?id=" + params;
			authFetch(route).then(res => {
				res.json().then(json => {
					this.addrOptions = json.items;
					this.receiver.addr_id = json.items[0].id;
					this.receiver.destCity = json.items[0].lokasi;
					this.destCity = json.items[0].kota + " / " + json.items[0].kecamatan;
					$(this.$refs.formReceiver).modal("show");
					console.log("pipi", json.items[0].lokasi);
				});
			});
        },
		onChangeDestCity: function(val) {
			this.receiver.addr_id = val;
		},
		cancelDraft: function(ev) {
			let route = this.$router.resolve({
				path: "/riwayat/draft-kiriman"
			});
			window.open(route.href,"_self");
		},
		findBuyer() {
			if (this.receiver.find_buyer == undefined ) {
				var search = [];
			} else {
				var search = this.receiver.find_buyer;
			}
			if (search.length > 3 || search.length == 0) {
				this.loadingBuyer = true;
				authFetch(
					`/shipper/buyer?keyword=${escape(search)}`
				).then(res => {
					res.json().then(json => (this.buyerOptions = json.data));
					this.loadingBuyer = false;
				});
			}
		},
		findCatalogue() {
			var search = this.item.find_catalogue;
			if ((search.length > 3 || search.length == 0) && !this.loadingCatalogue) {
				this.loadingCatalogue = true;
				authFetch(
					`/catalog?draw=1&columns%5B0%5D%5Bdata%5D=id&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=true&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=no&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=false&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=name&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=true&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B3%5D%5Bdata%5D=price&columns%5B3%5D%5Bname%5D=&columns%5B3%5D%5Bsearchable%5D=true&columns%5B3%5D%5Borderable%5D=true&columns%5B3%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B3%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B4%5D%5Bdata%5D=weight&columns%5B4%5D%5Bname%5D=&columns%5B4%5D%5Bsearchable%5D=true&columns%5B4%5D%5Borderable%5D=true&columns%5B4%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B4%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B5%5D%5Bdata%5D=5&columns%5B5%5D%5Bname%5D=&columns%5B5%5D%5Bsearchable%5D=true&columns%5B5%5D%5Borderable%5D=false&columns%5B5%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B5%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B6%5D%5Bdata%5D=created_at&columns%5B6%5D%5Bname%5D=&columns%5B6%5D%5Bsearchable%5D=true&columns%5B6%5D%5Borderable%5D=true&columns%5B6%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B6%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B7%5D%5Bdata%5D=7&columns%5B7%5D%5Bname%5D=&columns%5B7%5D%5Bsearchable%5D=true&columns%5B7%5D%5Borderable%5D=false&columns%5B7%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B7%5D%5Bsearch%5D%5Bregex%5D=false&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&start=0&length=10&search%5Bvalue%5D=${escape(search)}&search%5Bregex%5D=false&_=1691045642170`
				).then(res => {
					res.json().then(json => (this.catalogueOptions = json.data));
					this.loadingCatalogue = false;
				});
			}
		},
		showItem: function() {
			const e = this.$refs;
			$(e.formDialog).modal("show");
		},
		showCustomCourier: function() {
			const e = this.$refs;
			$(e.formDialogKurir).modal("show");
		},
		showItemDropOff: function() {
			const e = this.$refs;
			$(e.formDialogDropOff).modal("show");
		},
		onChangeWarehouse: function(e)
		{
			if (this.receiver.confirm && this.item.confirm) {
				var data = {};
				data.addr_id = this.receiver.addr_id;
				data.sender_addr_id = this.sender.addr_id;
				data.weight = this.item.weight;
				data.order_type = this.form.order_type;
				//this.loadAllOngkir(data);
				this.mapOngkir();
			}
			this.sender.error = false;
		},
		showReceiver: function() {
			const e = this.$refs;
			$(e.formReceiver).modal("show");
		},
		onSelectBuyer(id) {
			const e = this.$refs;
			var newArray = this.buyerOptions.filter(function (el) {
				return el.id == id;
			});
			var buyer = newArray[0];
			this.receiver = {};
			this.receiver.destName = buyer.receiver_name;
			this.receiver.destTel = buyer.receiver_phone;

			this.addrOptions = [{
				id: buyer.addr_id,
				kodepos: buyer.receiver_postcode,
				lokasi: buyer.kota + " / " + buyer.receiver_kecamatan,
				receiver_addr_code: buyer.receiver_addr_code
			}];
			this.receiver.addr_id = buyer.addr_id;
			this.receiver.addr_detail = buyer.receiver_address;
			this.destCity = buyer.kota + " / " + buyer.receiver_kecamatan;
			this.saveReceiver();
		},
		deletedItem: function(id, name) {
			const e = this.$refs;
			Swal.fire({
				title: 'Hapus Katalog Produk',
				html: "Anda anda yakin menghapus <strong>"+name+"</strong></br>dari katalog produk ?",
				icon: 'warning',
				width: '600px',
				showCancelButton: true,
				confirmButtonColor: '#e60000',
				confirmButtonText:'Hapus',
				cancelButtonText: 'Batalkan',
			}).then((result) => {
				if (result.isConfirmed) {
					this.submitting = true;
					var data = {
						"id_catalog": parseInt(id),
					};

					authFetch("/catalog/deleted", {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(data),
					})
					.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
					})
					.then((js) => {
						this.submitting = false;
						this.findCatalogue();
					})
				}
			})
		},
		onSelectCatalogue(id, categoryId, name) {
			const e = this.$refs;
			this.categoryOptions.push({"id" : categoryId, "name" : name, "key" : categoryId});
			var newArray = this.catalogueOptions.filter(function (el) {
				return el.id == id;
			});
			var catalogue = newArray[0];
			this.tempItem.item_type = 'new';
			this.tempItem.item_name = catalogue.name;
			this.tempItem.item_category = catalogue.category_id;
			this.tempItem.category_name = catalogue.category_name;
			this.tempItem.item_value = parseFloat(catalogue.price);
			this.tempItem.weight = catalogue.weight;
			this.tempItem.length = catalogue.length;
			this.tempItem.width = catalogue.width;
			this.tempItem.height = catalogue.height;
			this.tempItem.item_save = false;
			this.saveItem();
		},
		selectService: function(sid, item)
		{
			var now = moment();
			var tomorrow = moment().add(1,'days');
			// this.service = this.resultOngkir[sid];
			this.service = this.listCourierSelect[sid];
			this.form.courier_id = this.service.id;
			this.showServiceList = false;
			this.item.weight_volume = this.service.weight;

			if (this.form.courier_id == 5) {
				this.formItem.insurance_type = false;
			}

			if (item == "1") {
				this.droppOffDisabled = false;
			} else {
				this.droppOffDisabled = true;
				this.form.order_type = "0";
			}

			if (this.og_status == 1 || this.pickTimeExcept.includes(this.service.kurir)) {
				if (this.service.kurir == 'JNTCargo'){
					Swal.fire({
					title: "Info Waktu Penjemputan",
					icon: "info",
					html:
						'<h5>Kurir <b>JNT Cargo</b><br></h5> ' +
						'Order sebelum jam 15.00 akan di pickup pada hari yang sama dan <br> order setelah jam 15.00 akan dipickup pada hari berikutnya',
					showCloseButton: true
				});
				} else {
					Swal.fire({
					title: "Info Waktu Penjemputan",
					icon: "info",
					html:
						'<h5>Kurir <b>'+this.service.kurir+' Express</b><br></h5> ' +
						'Order sebelum jam 15.00 akan di pickup pada hari yang sama dan <br> order setelah jam 15.00 akan dipickup pada hari berikutnya',
					showCloseButton: true
				});
				}
			} else if (this.og_status == 1 || this.ServicePickTimeExcept.includes(this.service.service)) {
				Swal.fire({
					title: "Info Waktu Penjemputan",
					icon: "info",
					html:
						'<h5>Service <b>'+this.service.service+" </b> kurir <b> "+this.service.kurir+' Express</b><br></h5> ' +
						'Order sebelum jam 12.00 akan di pickup pada hari yang sama dan <br> order setelah jam 12.00 akan dipickup pada hari berikutnya',
					showCloseButton: true
				});
			}

			if (moment() < moment("08:00", 'HH:mm')) {
				this.form.pickup_time = now.format("YYYY-MM-DD 08:00:00");
				this.form.pickupend_on = now.format("YYYY-MM-DD 12:00:00");
			} else if (moment() < moment("12:00", 'HH:mm')) {
				this.form.pickup_time = now.format("YYYY-MM-DD 12:00:00");
				this.form.pickupend_on = now.format("YYYY-MM-DD 16:00:00");
			} else if (moment() < moment("16:00", 'HH:mm')) {
				this.form.pickup_time = now.format("YYYY-MM-DD 16:00:00");
				this.form.pickupend_on = now.format("YYYY-MM-DD 20:00:00");
			} else {
				this.form.pickup_time = tomorrow.format("YYYY-MM-DD 08:00:00");
				this.form.pickupend_on = tomorrow.format("YYYY-MM-DD 12:00:00");
			}

			this.countValue();
			this.submitType = 'draft';
			this.form.submit = 'draft'
		},

		validate_wa: async function(nomor)
		{
			const response = await authFetch("/order/whatsapp", {
				headers:{
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify({
					"nomor" : nomor
				}),
			});
			let json = await response.json();

			if (json){
				this.validasi_wa = json.success;
			} else {
				this.validasi_wa = false;
			}
		},

		validate_retur: async function(nomor)
		{
			const response = await authFetch("/order/retur_validate", {
				headers:{
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify({
					"nomor" : nomor
				}),
			});
			let json = await response.json();

			if (json){
				this.retur_exist = json;
			} else {
				this.retur_exist = false;
			}
		},
		changeCourier: function(e)
		{
			// if (e.target.checked == false && parseInt(e.target.value) == 1) {
			// 	$("#flat_status").prop("checked", false);
			// 	this.aktifkan_flat_idx = $("#flat_status").prop("checked", false);
			// }

			let checkData = this.dataPayload.find((element) => element.courier_id === e.target.value);
			if (checkData == undefined){
				// if (parseInt(e.target.value) == 1){
				// 	this.dataPayload.push({
				// 		"courier_id": e.target.value,
				// 		"status": e.target.checked == true ? 1 : 0,
				// 		"status_flat": $("#flat_status").prop("checked"),
				// 	});
				// } else {
					this.dataPayload.push({
						"courier_id": e.target.value,
						"status": e.target.checked == true ? 1 : 0
					});
				// }
			} else {
				// if (parseInt(e.target.value) == 1){
				// 	checkData.status = e.target.checked == true ? 1 : 0;
				// 	checkData.status_flat = $("#flat_status").prop("checked");
				// } else {
					checkData.status = e.target.checked == true ? 1 : 0;
				// }
			}
			const self = this.dataCustomCourier.find((element) => element.courier_id === e.target.value);
			self.status = e.target.checked == true ? 1 : 0;
		},
		saveCourier: async function()
		{
			this.submitting = true;
			const response = await authFetch("/order/courier/update_courier", {
				headers:{
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify(this.dataPayload),
			});
			let json = await response.json();
			if (json.succes == 'ok'){
				this.submitting = false;
				this.customSwal.fire({
					title: 'Berhasil menyimpan pengaturan<br />kurir pengiriman',
					showConfirmButton: false,
					showCancelButton: true,
					cancelButtonText: 'Lanjutkan Mengisi',
				}).then((result) => {
					this.loadCourier();
				});
				$(this.$refs.formDialogKurir).modal("hide");
			}
		},
		getBiayaAsuransi(item, service, nilai_ongkir)
		{
			if (!this.formItem.insurance_type) return 0;

			var cod_fee_ppn = ((item.item_value + nilai_ongkir) / (1-((this.service.feecod / 100) + ((this.service.ppn / 100) * this.service.feecod / 100))) * (this.service.feecod / 100)) + (((item.item_value + nilai_ongkir) / (1-((this.service.feecod / 100) + ((this.service.ppn / 100) * this.service.feecod / 100))) * (this.service.feecod / 100)) * (this.service.ppn / 100))
			if (this.form.courier_id == 7){
				var itemVal = item.codValue > 0 ? item.codValue : (item.item_value + nilai_ongkir + cod_fee_ppn);
			} else if (this.form.courier_id == 3){
				var itemVal =  item.codStatus == true ? (item.codValue > 0 ? item.codValue : (item.item_value + nilai_ongkir + cod_fee_ppn)) : item.item_value;
			} else {
				var itemVal = item.item_value;
			}

			var insFee = 0;
			if (itemVal > service.insmin) {
				if (service.insfee) {
					insFee = service.insfee;
				} else if (service.ins) {
					insFee = (service.ins * itemVal) / 100;
				}
			}

			if (this.form.courier_id == 5 && insFee < 2750) {
				insFee = 2750;
			}
			return insFee;
		},
		getBiayaOngkir(item, service)
		{
			var nilai_ongkir = service.price - service.disc;
			if (service.ppn_ongkir_inc == 0) {
				// var xnilai_ongkir = nilai_ongkir * 100 / (100 + service.ppn_ongkir);
				var xnilai_ongkir = Math.round((nilai_ongkir * (100 + service.ppn_ongkir)) / 100);
				nilai_ongkir = xnilai_ongkir;
				// nilai_ongkir = nilai_ongkir * 1.01; // +ppn 1%
			}
			return nilai_ongkir;
		},
		getBiayaCOD(item, service)
		{
			if (item.codStatus) {
				var codFee = ((item.codValue > 0 ? item.codValue : item.item_value) * service.feecod) / 100;
				var totalFeeCod = codFee + (codFee * service.ppn) / 100;
				return totalFeeCod;
			} 
			return 0;
		},
		countValue() {
			this.codValueErr = false;
			this.errors = [];
			this.hitungBiaya();

			// if (!this.item.codStatus && this.item.codValue < 1)
			// {
			// 	this.codValueErr = true;
			// 	this.errors.push({
			// 		message: "Nilai paket harus diisi minimal Rp. 1",
			// 	});
			// 	return;
			// }
			// else if (this.item.codStatus && (this.item.codValue < 1000 || this.item.codValue >2500000))
			// {
			// 	this.codValueErr = true;
			// 	this.errors.push({
			// 		message: "Nilai COD harus diisi antara Rp 1.000 - Rp 2.500.000",
			// 	});
			// 	return;
			// }
		},
		hitungBiaya: function()
		{
			if (!this.sender.id || !this.receiver.confirm || !this.item.confirm || !this.service.id) {
				return;
			}

			if (this.item.codValue > 0) {
				var cod_value = this.item.codValue;
			} else if (this.item.codStatus) {
				var cod_value = this.item.item_value;
			} else {
				this.item.codValue = 0;
			}

			var nilai_ongkir = this.getBiayaOngkir(this.item, this.service);
			var feeCod = this.getBiayaCOD(this.item, this.service);
			var insFee = this.getBiayaAsuransi(this.item, this.service, nilai_ongkir);
			this.formItem.nilai_ongkir = nilai_ongkir;
			this.formItem.feeCod = feeCod;
			this.formItem.ppnCod = this.service.ppn;
			this.formItem.fee_cod = this.service.feecod;
			this.formItem.insurance_fee = insFee;
			this.formItem.totalReceived = cod_value - nilai_ongkir - feeCod - insFee;
			if (this.item.codStatus) {
				if (this.item.codValue && this.formItem.totalReceived < 1) {
					this.availableCost = false;
				} else {
					this.availableCost = true;
				}
			} else {
				this.availableCost = true;
			}
		},
		showServiceOption: function()
		{
			if (this.resultOngkir.length === 1) return;
				this.showServiceList = true;
				this.resetService();
		},
		selectWarehouse: function() {
			this.show = !this.show;
		},
		draftForm: function() {
			if (!this.sender.addr_id) {
				this.sender = {};
				this.sender.error = true;
			} else if (!this.receiver.confirm) {
				this.receiver = { confirm: false };
				this.receiver.error = true;
			} else if (this.form.courier_id > 0) {
				$(this.$refs.createOrder).trigger('click');
				return;
			} else {
				$(this.$refs.draftCourier).modal("show");
				return;
			}

			this.customSwal.fire({
				title: 'Simpan ke draft gagal, halaman tidak<br />boleh kosong.',
				html: '<img src="' + this.getImage('ok') + '" class="d-none" />Kamu harus mengisi minimal alamat pengirim<br />dan alamat penerima.',
				showConfirmButton: false,
				showCancelButton: true,
				cancelButtonText: 'Lanjutkan Mengisi',
			});
		},
		submitDraftForm: function() {
			if (!this.form.courier_id) {
				this.customSwal.fire({
					title: 'Simpan ke draft gagal, kurir tidak<br />boleh kosong.',
					html: '<img src="' + this.getImage('ok') + '" class="d-none" />Kamu harus mengisi kurir pengiriman.',
					showConfirmButton: false,
					showCancelButton: true,
					cancelButtonText: 'Lanjutkan Mengisi',
				});
			} else {
				$(this.$refs.createOrder).trigger('click');
			}
		},
		submitForm: function(ev) {
			this.$gtag.event(`click`, {
				'event_name': 'Buat Pesanan'
			});
			const self = this;
			this.form.shipper_addr_id = this.sender.id;
			this.form.sender_name = this.dropship.checker ? this.dropship.senderName : this.sender.senderName;
			this.form.sender_addr_id = this.sender.addr_id;
			this.form.sender_addr_code = this.sender.addr_code;
			this.form.sender_address = this.sender.addr_detail;
			this.form.sender_postcode = this.sender.kodepos;
			this.form.sender_phone = this.dropship.checker ? this.dropship.senderTel : this.sender.senderTel;
			this.form.code = this.sender.code;
			this.form.courier_id = this.form.courier_id ? this.form.courier_id : this.service.id;

			if (ev.submitter) {
				self.form.submit = ev.submitter.id;
			} else {
				self.form.submit = this.submitType;
			}

			self.method = this.$route.params.id ? "PUT" : "POST";

			var items = [];

			// merge all items
			let item = {
				...this.receiver,
				...this.item,
				//...this.service,
			};
			item.ins_fee = this.service.insfee;
			item.ins_min = this.service.insmin;
			item.ins_percent = this.service.ins;
			item.feeCod = this.service.feecod;
			item.service_code = this.service.service;
			item.ongkir_dasar = this.service.price;
			item.disc = this.service.disc;
			//hitung asuransi

			item.insurance_type = this.formItem.insurance_type;
			var itemVal = item.codValue;
			item.ppn_ongkir_inc = this.service.ppn_ongkir_inc;
			item.ppnCod = this.service.ppn;
			item.fee_cod = this.service.feecod;
			item.nilai_ongkir = this.getBiayaOngkir(this.item, this.service);
			item.insurance_fee = this.getBiayaAsuransi(this.item, this.service, item.nilai_ongkir);
			if (item.codStatus) {
				item.totalFeeCod = this.getBiayaCOD(this.item, this.service);
				item.totalReceived = this.formItem.totalReceived;
			} else {
				item.totalReceived = -(
					item.nilai_ongkir + item.insurance_fee
				);
			}

			var cod_fee = this.formItem.fee_cod / 100;
			var cod_ppn = (this.formItem.ppnCod / 100) * cod_fee;
			var cod_fee_ppn = cod_fee + cod_ppn;
			var calculate_cod_fee = 1 -cod_fee_ppn; 
			var grand_total = Math.ceil((this.item.item_value + this.formItem.nilai_ongkir + this.formItem.insurance_fee) / calculate_cod_fee);
			var cod_fee_and_ppn_percent = Math.round(grand_total * this.formItem.fee_cod / 100);
			var cod_fee_and_ppn = Math.round((cod_fee_and_ppn_percent + ( cod_fee_and_ppn_percent * this.formItem.ppnCod / 100)));

			if (item.codStatus == false || item.codStatus == true && item.codValue > 0){
				item.total_biaya = item.codStatus ? (this.formItem.nilai_ongkir + this.formItem.feeCod + this.formItem.insurance_fee) : (this.formItem.nilai_ongkir + this.formItem.insurance_fee);
			} else {
				item.total_biaya = this.formItem.nilai_ongkir + this.formItem.insurance_fee + Math.round(((this.item.item_value + this.formItem.nilai_ongkir + this.formItem.insurance_fee) / (1-((this.formItem.fee_cod / 100) + ((this.formItem.ppnCod / 100) * this.formItem.fee_cod / 100))) * (this.formItem.fee_cod / 100)) + (((this.item.item_value + this.formItem.nilai_ongkir + this.formItem.insurance_fee) / (1-((this.formItem.fee_cod / 100) + ((this.formItem.ppnCod / 100) * this.formItem.fee_cod / 100))) * (this.formItem.fee_cod / 100)) * (this.formItem.ppnCod / 100)));
			}

			if (item.codStatus && item.codValue <= 0) {
				var nilai_cod = grand_total - cod_fee_and_ppn - this.formItem.nilai_ongkir - this.formItem.insurance_fee;

				if(nilai_cod == this.item.item_value){
					item.codValue = grand_total;
				} else {
					var grand_total2 = Math.floor((this.item.item_value + this.formItem.nilai_ongkir + this.formItem.insurance_fee) / calculate_cod_fee);
					item.codValue = grand_total2;
				}
			}

			item.note = this.form.note;

			items.push(item);

			var allData = { group: self.form, items: items };
			if (self.form.submit == "editDraft"){
				this.submitting = true;
				$('.loading-overlay').addClass('show');
				var urlDraft = "/order/draft/"+this.$route.params.id;
				authFetch(urlDraft, {
					method: "PUT",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(allData)
				})
				.then(res => {
					this.submitting = false;
					if (res.status != 200) {
						this.customSwal.fire({
							title: 'Gagal menyimpanan',
							html: 'Koneksi kamu terputus, coba ulangi beberapa saat lagi ya.',
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: 'Kembali',
						});
					}
					return res.json();
				})
				.then(js => {
					$('.loading-overlay').removeClass('show');
					if (js.success == true) {
						this.customSwal.fire({
							// title: 'Berhasil Simpan Data Pesanan',
							html: '<img src="' + this.getImage('ok') + '" class="d-block w-75 m-auto" />Berhasil Simpan Data Pesanan.',
							showDenyButton: true,
							confirmButtonText: 'Lanjutkan pesanan',
							denyButtonText: 'Kembali',
						}).then((result) => {
							this.resetFormItem();
							if (result.isConfirmed) {
								let route = this.$router.resolve({
									path: "/riwayat/draft-kiriman"
								});
								window.open(route.href,"_self");
							} else {
								let route = this.$router.resolve({
									path: "/riwayat/draft-kiriman"
								});
								window.open(route.href,"_self");
							}
						});
					} else {
						let route = this.$router.resolve({
							path: "/riwayat/draft-kiriman"
						});
						window.open(route.href,"_self");
					}
				});
			} else if (self.form.submit == "draft") {
				this.customSwal.fire({
					title: 'Simpan pesanan sebagai draft?',
					html: '<img src="' + this.getImage('ok') + '" class="d-none" />Pesanan kamu akan disimpan sebagai draft dan<br />belum diproses ke kurir, simpan ke draft dan<br />proses kapan saja.',
					showDenyButton: true,
					confirmButtonText: 'Simpan ke draft',
					denyButtonText: 'Batal',
				}).then((result) => {
					if (result.isConfirmed) {
						$(this.$refs.draftCourier).modal('hide');
						this.submitting = true;
						$('.loading-overlay').addClass('show');
						// var urlDraft = self.method == "PUT" ? "/order" : "/order/group";
						var urlDraft = self.method == "PUT" ? "/order" : "/order/darft";
						authFetch(urlDraft, {
							method: self.method,
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json"
							},
							body: JSON.stringify(allData)
						})
						.then(res => {
							this.submitting = false;
							if (res.status != 200) {
								this.customSwal.fire({
									title: 'Simpan ke draft gagal',
									html: 'Koneksi kamu terputus, coba ulangi beberapa saat lagi ya.',
									showConfirmButton: false,
									showCancelButton: true,
									cancelButtonText: 'Kembali',
								});
							}
							return res.json();
						})
						.then(js => {
							$('.loading-overlay').removeClass('show');
							this.errors = [];
							if (js.success) {
								this.customSwal.fire({
									title: 'Pesanan kamu berhasil disimpan<br />sebagai draft',
									html: '<img src="' + this.getImage('ok') + '" class="d-block w-75 m-auto" />Pesanan kamu berhasil disimpan sebagai draft dan<br />belum diproses ke kurir.',
									showDenyButton: true,
									confirmButtonText: 'Buat Pesanan Lagi',
									denyButtonText: 'Lihat Riwayat Draft',
								}).then((result) => {
									this.resetFormItem();
									if (result.isDenied) {
										sessionStorage.filterData = '{"draft": 1}';
										let route = this.$router.resolve({
											path: "/riwayat/draft-kiriman"
										});
										window.open(route.href,"_self");
										// const routeData = this.$router.resolve({name: 'Riwayat'});
										// const routeData = this.$router.resolve({name: 'Draft Kiriman'});
										window.open(routeData.href, '_self');
									} else if (this.$route.params.id) {
										const routeData = this.$router.resolve({name: 'Buat Pesanan'});
										window.open(routeData.href, '_self');
									}
								});
							} else {
								for (var key in js.details) {
									if (js.details.hasOwnProperty(key)) {
										this.errors.push(js.details[key]);
									}
								}

								if (js.msg == 'Verify KTP'){
									this.verifKtp();
								} else if (js.msg == 'Total biaya') {
									this.customSwal.fire({
											// title: 'Yakin ingin proses pesanan sekarang?',
											html: '<img src="' + this.getImages('alert-info') + '" class="d-block m-auto"/><br /><h4>Harga produk harus lebih besar atau sama dengan biaya ongkir dan COD fee</h4>',
											showDenyButton: true,
											confirmButtonText: 'Ubah Sekarang',
											denyButtonText: 'Kembali',
										}).then((result) => {
										if (result.isConfirmed) {
											this.showItem();
										}
									});
								} else {
									this.customSwal.fire({
									title: 'Simpan ke draft gagal, ' + (js.msg ? js.msg : 'halaman tidak<br />boleh kosong'),
									html: js.output[0].message ? js.output[0].message : 'Kamu harus mengisi minimal alamat pengirim dan alamat penerima',
									showConfirmButton: false,
									showCancelButton: true,
									cancelButtonText: 'Lanjutkan Mengisi',
								});
								}
							}
						});
					}
				});
			// } else if (self.form.submit == "submit") {
			} else {
				this.customSwal.fire({
					title: 'Yakin ingin memproses pesanan?',
					html: '<img src="' + this.getImage('ok') + '" class="d-none" />',
					showDenyButton: true,
					confirmButtonText: 'Proses',
					denyButtonText: 'Batal',
				}).then((result) => {
					if (result.isConfirmed) {
						this.submitting = true;
						var urlSubmit = self.method == "PUT" ? "/order/" + this.$route.params.id : "/order/group";
						authFetch(urlSubmit, {
							method: this.method,
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json"
							},
							body: JSON.stringify(allData)
						})
						.then(res => {
							this.submitting = false;
							if (res.status != 200) {
								this.customSwal.fire({
									title: 'Buat pesanan gagal',
									html: 'Koneksi kamu terputus, coba ulangi beberapa saat lagi ya.',
									showConfirmButton: false,
									showCancelButton: true,
									cancelButtonText: 'Kembali',
								});
							}
							return res.json();
						})
						.then(js => {
							if (js.success) {
								let dataorder = js.data;
								let countSuccess = 0;
								let countFail = 0;
								let finalMsg = "";

								//cek sukses semua
								let allSuccess = dataorder.filter(function(arr, i) {
									return (
										(arr.status == true && arr.msg == null) ||
										arr.no_resi != ""
									);
								});
								countSuccess = allSuccess.length;

								//cek ada yang gagal & sukses
								let otherFail = dataorder.filter(function(arr, i) {
									return (
										arr.status == false &&
										arr.no_resi == "" &&
										arr.msg != null
									);
								});
								countFail = otherFail.length;

								if (countSuccess > 0) {
									finalMsg = "Semua item pesanan berhasil dibuat.";
								}

								if (countFail > 0) {
									let detailData =
										'<div style="overflow-y: auto;max-height:150px;" class="border rounded p-2 mt-2 text-muted text-left">';
									for (let i in otherFail) {
										detailData +=
											"<b>Kode " +
											otherFail[i].no_order +
											"</b><br><i>" +
											otherFail[i].msg +
											"</i><br>";
									}
									detailData += "</div>";

									finalMsg =
										'<i class="fa fa-exclamation-triangle"></i> Ada item pesanan yang gagal dibuat.' +
										detailData;
								} else {
									finalMsg = "Semua item pesanan berhasil dibuat.";
								}

								this.customSwal.fire({
									title: 'Pesanan Berhasil Dibuat',
									html: '<img src="' + this.getImage('ok') + '" class="d-block w-75 m-auto" />Pesanan kamu berhasil dibuat, silahkan cek label<br />kamu dan tempel di paket kamu.',
										showConfirmButton: false,
										showCancelButton: true,
										cancelButtonText: 'Lanjutkan',
								}).then(() => {
									if(this.item.codStatus == true){
										if (this.item.codValue == 0){
											logEvent(analytics, 'web_regularCOD');
										} else {
											logEvent(analytics, 'web_customCOD');
										}
									}

									if (this.form.submit == 'submit') {
										sessionStorage.filterData = '{"rpick": 1}';
										const routeData = this.$router.resolve({name: 'Riwayat'});
										// const routeData = this.$router.resolve({name: 'Riwayat Kiriman'});
										window.open(routeData.href, '_self');
									} else {
										const routeData = this.$router.resolve({name: 'Buat Pesanan'});
										window.open(routeData.href, '_self');
									}
									this.resetFormItem();
								});
							} else {
								if (js.msg) {
									if (js.msg == 'Verify KTP'){
										this.verifKtp();
									} else if (js.msg == 'Total biaya') {
										this.customSwal.fire({
												// title: 'Yakin ingin proses pesanan sekarang?',
												html: '<img src="' + this.getImages('alert-info') + '" class="d-block m-auto"/><br /><h4>Harga produk harus lebih besar atau sama dengan biaya ongkir dan COD fee</h4>',
												showDenyButton: true,
												confirmButtonText: 'Ubah Sekarang',
												denyButtonText: 'Kembali',
											}).then((result) => {
											if (result.isConfirmed) {
												this.showItem();
											}
										});
									} else {
										this.customSwal.fire({
											title: js.msg ? js.msg : 'Buat pesanan gagal, halaman tidak<br />boleh kosong',
											html: js.output[0].msg ? js.output[0].msg : 'Kamu harus mengisi minimal alamat pengirim dan alamat penerima',
											showConfirmButton: false,
											showCancelButton: true,
											cancelButtonText: 'Lanjutkan Mengisi',
										});
									}
								} else if (js.message) {
									this.customSwal.fire({
										title: 'Buat pesanan gagal',
										html: js.message,
										showConfirmButton: false,
										showCancelButton: true,
										cancelButtonText: 'Lanjutkan Mengisi',
									});
								} else {
									// for (let i = 0; i < js.output.length; i++) {
									// 	Swal.fire("Proses gagal", `${js.output[i].msg}`, "error");
									// 	//this.$router.push("/order");
									// }
								}
							}
						});
					}
				});
			}
			ev.preventDefault();
			
		},
		klikCatalog: function() {
			this.tempItem.item_type = 'old'
			this.item.find_catalogue = ""
			this.findCatalogue();
		},
		getImages(name) {
			if (!name) return;
			const fileName = name.toLowerCase();
			return require(`../../assets/img/${fileName}.png`);
		},
		saveItem: function(ev) {
			this.$forceUpdate();
			// this.item = JSON.parse(JSON.stringify(this.tempItem));
			this.item = this.tempItem;
			if (this.item.item_save) {
				var data = {
					"name": this.item.item_name,
					"category_id": this.item.item_category,
					"weight": parseFloat(this.item.weight),
					"price": parseFloat(this.item.item_value),
					"width": Math.ceil(this.item.width),
					"height": Math.ceil(this.item.height),
					"length": Math.ceil(this.item.length),
				};

				authFetch("/catalog", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				})
				.then((res) => {
					const e = this.$refs;
					if (res.status == 200){
						this.customSwal.fire({
							title: 'Tambah Katalog Produk Berhasil',
							html: `<img src="${this.getImage('ok')}" class="d-block w-75 m-auto" />Anda berhasil menambahkan <strong>${this.item.item_name}</strong><br />ke katalog produk.`,
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: 'Lanjutkan Mengisi',
						}).then((result) => {
							this.item.item_save = false;
							this.tempItem.item_save = false;
						});
					} else {
						this.customSwal.fire({
							title: 'Tambah Katalog Produk Gagal',
							html: `<img src="${this.getImage('ok')}" class="d-none" />Anda gagal menambahkan <strong>${this.item.item_name}</strong><br />ke katalog produk.`,
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: 'Lanjutkan Mengisi',
						}).then((result) => {
							return false;
						});
					}
				});
			}

			this.item.confirm = true;
			const e = this.$refs;
			var data = {};
			data.addr_id = this.receiver.addr_id;
			data.sender_addr_id = this.sender.addr_id;
			data.weight = this.item.weight;
			data.order_type = this.form.order_type;

			if (!this.sender.addr_id) {
				this.sender.error = true;
			} else if (!this.receiver.confirm) {
				this.receiver.error = true;
			} else {
				//this.loadAllOngkir(data);
				this.mapOngkir();
			}
			this.$forceUpdate();
			this.item.error = false;
			// this.tempItem = JSON.parse(JSON.stringify(this.item));
			this.tempItem = this.item;
			$(e.formDialog).modal("hide");
			this.$forceUpdate();
		},
		setCategoryName: function() {
			var category = this.categoryOptions.filter(el => {
				return el.id == this.tempItem.item_category;
			});

			if (category.length > 0){
				this.tempItem.category_name = category[0].name;
				this.formItem.category_name = category[0].name;
			} else {
				this.tempItem.category_name = this.categoryOptions[0].name;
				this.formItem.category_name = this.categoryOptions[0].name;
			}
		},
		saveReceiver: function(ev) {
			this.receiver.confirm = true;
			var location = this.addrOptions.filter(el => {
				return el.id == this.receiver.addr_id;
			});

			this.receiver.kodepos = location[0].kodepos;
			this.receiver.destCity = location[0].lokasi;
			this.receiver.receiver_addr_code = location[0].receiver_addr_code;

			this.validate_wa(this.receiver.destTel);
			this.validate_retur(this.receiver.destTel);

			if (this.$route.params.data_from_ongkir){
				this.item.weight = this.$route.params.data_product.weight;
				this.formItem.weight = this.$route.params.data_product.weight;
				this.tempItem.weight = this.$route.params.data_product.weight;
				this.item.length = this.$route.params.data_product.l;
				this.formItem.length = this.$route.params.data_product.l;
				this.tempItem.length = this.$route.params.data_product.l;
				this.item.width = this.$route.params.data_product.w;
				this.formItem.width = this.$route.params.data_product.w;
				this.tempItem.width = this.$route.params.data_product.w;
				this.item.height = this.$route.params.data_product.h;
				this.formItem.height = this.$route.params.data_product.h;
				this.tempItem.height = this.$route.params.data_product.h;
				this.loadReceiver(this.$route.params.data_product);
			} 

			const e = this.$refs;
			if (this.item && this.item.confirm) {
				var data = {};
				data.addr_id = this.receiver.addr_id;
				data.sender_addr_id = this.sender.addr_id;
				data.weight = this.item.weight;
				data.order_type = this.form.order_type;
				//this.loadAllOngkir(data);
				this.mapOngkir();
			}
			this.receiver.error = false;
			if (!this.sender.addr_id) {
				this.sender.error = true;
			}
			if (!this.item.confirm) this.item.error = true;
			//this.hitungBiaya();
			$(e.formReceiver).modal("hide");
			this.$forceUpdate();	

			if (this.$route.params.data_from_ongkir){
				this.showItem();
			}
		},
		onToggleChangeService: function()
		{
            var listCourierSelect = [];
            for (let i = 0; i < this.resultOngkir.length; i++) {
                if (this.resultOngkir[i].category == this.service_category){
                    listCourierSelect.push(this.resultOngkir[i]);
                }
            }
            this.listCourierSelect = listCourierSelect;
		},
		closeNotifRetur: function()
		{
            this.retur_exist = false;
		},
	},
	components: {
		vSelect,
		maskedInput,
		CurrencyInput,
		Warehouse,
		StarRating,
	},
	mounted() { 
		const e = this.$refs;
		const self = this;
		var now = moment();
		var minDate = now.startOf("hour");
		if (now.format("H") >= 20) {
			minDate = minDate.add(1, "d").startOf("date");
		}
		if (!this.$route.params.id) {
			this.form.pickup_time = minDate.format("YYYY-MM-DD HH:mm:00");
		}
		this.customSwal = Swal.mixin({
			customClass: {
				// popup: 'w-fit',
				title: 'h4 tex-dark',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary w-49 font-weight-bold py-3',
				denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-3',
				cancelButton: 'btn btn-primary btn-block font-weight-bold py-3',
			},
			buttonsStyling: false
		});
		$("input.mask").each((i,ele)=>{
			let clone=$(ele).clone(false);
			clone.attr("type","number");
			clone.css("border-top-left-radius", "0.375rem");
			clone.css("border-bottom-left-radius", "0.375rem");
			let ele1=$(ele);
			clone.val(Number(ele1.val()).toLocaleString("en"));
			$(ele).after(clone);
			$(ele).hide();
			clone.mouseenter(()=>{
				ele1.show();
				clone.hide();
			})

			// if (ele1.val() == 0 || ele1.val() == undefined || ele1.val() == null || ele1.val() == ""){
				ele1.show();
				clone.hide();
			// } else {
				setInterval(()=>{
					let newv=Number(ele1.val()).toLocaleString("en");
					if(clone.val()!=newv){
						clone.val(newv);
					}
				},10)

				$(ele).mouseleave(()=>{
					$(clone).show();
					$(ele1).hide();
				})
			// }
		});
		this.loadDataUserDropOff();
		
		if (this.$route.params.data_from_ongkir){
			this.loadReceiver(this.$route.params.data_receiver_ongkir);
		} 
	},
	beforeDestroy() {
		// this.customSwal.fire({
		// 	title: 'Ingin keluar dari halaman buat pesanan?',
		// 	html: 'Pesanan yang sudah kamu isi tidak akan<br />disimpan jika kamu keluar.',
		// 	showDenyButton: true,
		// 	confirmButtonText: 'Keluar',
		// 	denyButtonText: 'Batal',
		// }).then((result) => {
		// 	if (result.isDenied) {
		// 		alert('beforeDestroy');
		// 		return false;
		// 	}
		// });
	},
};
</script>
<style scoped>
.description-text{
	font-size: 15px;
}
.btn-circle {
	width: 15px;
	height: 15px;
	padding: 0px 0px;
	border-radius: 10px;
	text-align: center;
	font-size: 10px;
	line-height: 1.42857;
}
.redesign .form-control, .redesign .vs__dropdown-toggle {
    min-height: 0;
}
@media (max-width: 575.98px) {
	.position-sticky {
		position: static !important;
	}
}
</style>
